import { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';

const isNonceError = (error: AxiosError<any>) => {
    return error.request.status === 401 && error.request.response === "nonce has already been used";
}

export const configureAxiosRetryForNonceError = (axiosClient) => {
    axiosRetry(axiosClient, {
        retries: 1,
        retryCondition: (error) => {
            console.error(error);
            return isNonceError(error);
        }
    });
}