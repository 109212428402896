import React, { useState } from 'react';
import type { FC } from 'react';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Toolbar,
  makeStyles, Button, Box
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import type { Theme } from 'src/theme';

import useAuth from '../../../hooks/useAuth';
import {useSnackbar} from 'notistack';
import HelpModal from '../../../views/shared/HelpModal';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.CARRIER_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 64
  }
}));

const TopBar: FC<TopBarProps> = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isHelpModalOpen, setHelpModalOpen] = useState<boolean>(false);

  const handleOpenHelpModal = (): void => {
    setHelpModalOpen(true);
  };

  const handleCloseHelpModal = (): void => {
    setHelpModalOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      history.push('/login');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo height="52px" />
        </RouterLink>
        <Box>
          <Button color="secondary" onClick={handleOpenHelpModal}>
            Help
          </Button>
          <Button onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </Toolbar>
      {isHelpModalOpen && (
        <HelpModal
          onClose={handleCloseHelpModal}
          open={isHelpModalOpen}
        />
      )}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
