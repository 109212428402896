type AtomLink = {
  $: {
    rel: string;
    href: string;
  };
};

export interface AtomLinkObject {
  'atom:link': AtomLink;
}

export const getAtomLink = (obj: AtomLinkObject): AtomLink | null => {
  if (!obj) {
    return null;
  }

  if (Array.isArray(obj['atom:link'])) {
    let atomLinks: AtomLink[] = obj['atom:link'] as AtomLink[];
    // find the self link
    return atomLinks.find(link => link.$?.rel === 'self');
  } else {
    return obj['atom:link'] as AtomLink;
  }
};

export const getHref = (atomLink: AtomLink): string => {
  return atomLink?.$?.href;
};

export const parseHrefForId = (href: string): string => {
  if (!href) {
    return null;
  }
  let lastSlashIndex = href.lastIndexOf('/');
  if (lastSlashIndex > 0 && href.length > lastSlashIndex + 1) {
    let locationId = href.substring(lastSlashIndex + 1);
    return locationId;
  }

  return null;
};

export const getIdFromAtomLinkObject = (obj: AtomLinkObject): string => {
  let atomLink = getAtomLink(obj);
  let href = getHref(atomLink);
  return parseHrefForId(href);
};

export const toArray = (arrayOrObj): Array<any> => {
  if (!arrayOrObj) {
    return [];
  }

  if (Array.isArray(arrayOrObj)) {
    return arrayOrObj;
  } else {
    return [arrayOrObj];
  }
};

export function convertOnOffStringToBoolean(value: string): boolean {
  if (value === undefined) {
    return false;
  }
  switch (value.toLowerCase()) {
    case 'on':
      return true;
    case 'off':
      return false;
    default:
      return false;
  }
}
