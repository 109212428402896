import { Dealer } from '../../../types/system';

export const toSystemDealer = (responseObj): Dealer => {
  let dealer = responseObj?.dealer;
  if (!dealer) {
    return null;
  }

  return {
    pid: dealer.pid,
    name: dealer.name,
    phone: dealer.phone,
    street: dealer.street1,
    unit: dealer.street2,
    city: dealer.city,
    state: dealer.state,
    zip: dealer.postal,
    country: dealer.country
  };
};

export const toSystemDealers = (responseObj): Dealer[] => {
  let dealers = responseObj?.dealers;
  if (!dealers) {
    return null;
  }

  let responseDealers = [];
  if (Array.isArray(dealers.dealer)) {
    responseDealers = dealers.dealer;
  } else {
    responseDealers = [dealers.dealer];
  }

  let dealerList = [];
  for (let dealer of responseDealers) {
    dealerList.push({
      pid: dealer.pid,
      name: dealer.name,
      phone: dealer.phone,
      street: dealer.street1,
      unit: dealer.street2,
      city: dealer.city,
      state: dealer.state,
      zip: dealer.postal,
      country: dealer.country
    });
  }
  return dealerList;
};

export const toSystemDealerRequest = requestObj => {
  let dealer = requestObj;

  return {
    dealer: {
      pid: dealer.pid,
      name: dealer.name,
      street1: dealer.street,
      street2: dealer.unit,
      city: dealer.city,
      state: dealer.state,
      country: dealer.country,
      postal: dealer.zip,
      phone: dealer.phone
    }
  };
};
