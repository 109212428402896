import { SystemProfile } from '../../../types/system';

export const toSystemProfile = (systemProfileObj: any): SystemProfile => {
  if (!systemProfileObj?.system_profile) {
    return null;
  }
  return {
    name: systemProfileObj.system_profile.name,
    indoorModel: systemProfileObj.system_profile.indoorModel,
    indoorSerial: systemProfileObj.system_profile.indoorSerial,
    outdoorModel: systemProfileObj.system_profile.outdoorModel,
    outdoorSerial: systemProfileObj.system_profile.outdoorSerial,
    firmware: systemProfileObj.system_profile.firmware,
    model: systemProfileObj.system_profile.model,
    zones: systemProfileObj.system_profile.zones?.zone
  };
};
