import { UserAccount } from '../../../types/user-account';

export const toUserAccount = (responseObj): UserAccount => {
  let user = responseObj?.user;

  if (!user) {
    return null;
  }

  return {
    email: user.email,
    username: user.username,
    firstName: user.first,
    lastName: user.last,
    mainPhone: user.phone1,
    mainExt: user.phone1Ext,
    secondaryPhone: user.phone2,
    secondaryExt: user.phone2Ext,
    promotions: user.promoEmail === 'true',
    street: user.address1,
    unit: user.address2,
    city: user.city,
    state: user.state,
    zip: user.postal,
    country: user.country,
    // below are unused values
    locations: [],
    password: ''
  };
};

export const toUpdateUserRequest = requestObj => {
  let user = requestObj;

  return {
    user: {
      email: user.email,
      username: user.username,
      first: user.firstName,
      last: user.lastName,
      phone1: user.mainPhone,
      phone1Ext: user.mainExt,
      phone2: user.secondaryPhone,
      phone2Ext: user.secondaryExt,
      promoEmail: user.promotions === true ? 'true' : '',
      address1: user.street,
      address2: user.unit,
      city: user.city,
      state: user.state,
      postal: user.zip,
      country: user.country
    }
  };
};

export const toUpdateUserPromotionRequest = (requestObj: UserAccount, promotions: boolean) => {
  let user = requestObj;

  return {
    email: user.email,
    username: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    mainPhone: user.mainPhone,
    mainExt: user.mainExt,
    secondaryPhone: user.secondaryPhone,
    secondaryExt: user.secondaryExt,
    promotions: promotions === true ? 'true' : '',
    address1: user.street,
    address2: user.unit,
    city: user.city,
    state: user.state,
    postal: user.zip,
    country: user.country
  };
};

export interface RefreshTokenResponse {
  username: string;
  accessToken: string;
}

export const toRefreshTokenResponse = (responseObj): RefreshTokenResponse => {
  let result = responseObj?.result;
  if (!result) {
    return null;
  }

  let token = result?.accessToken;
  if (!token) {
    return null;
  }

  let atomLinks = result['atom:link'];
  if (!atomLinks) {
    return null;
  }

  let userLink = atomLinks.find(link => link['$'].rel === 'http://www.api.ing.carrier.com/rels/user');
  if (!userLink) {
    return null;
  }

  let href = userLink['$']?.href;
  if (!href) {
    return null;
  }

  let i = href.lastIndexOf('/');
  if (i < 0) {
    return null;
  }

  let receivedUsername = href.substring(i + 1);
  if (!receivedUsername) {
    return null;
  }

  return {
    username: receivedUsername,
    accessToken: token
  };
};
