export const infinityConfig = {
  baseURL: process.env.REACT_APP_INFINITY_BASE_URL,
  consumerKey: process.env.REACT_APP_INFINITY_CONSUMER_KEY,
  consumerSecret: process.env.REACT_APP_INFINITY_CONSUMER_SECRET,
  legacyOauth2Redirect: process.env.REACT_APP_LEGACY_OAUTH2_AUTHORIZE_URL,
};

export enum Brand {
  Carrier = 'Carrier',
  Ion = 'Ion',
  Bryant = 'Bryant',
}

export const brand = {
  value: Brand.Carrier, // used to pass into the rest-api
  logo: '/static/images/carrier-logo.png',
  mobileAppLogo: '/static/icons/carrier-mobile-app-logo.png',
  mobileAppName: 'Carrier Home',
  mobileAppStoreUrl: 'https://apps.apple.com/us/app/id1528608992',
  mobileAppAppStoreQrCode: '/static/images/carrier-ios-qr.svg',
  mobileAppGooglePlayUrl: 'https://play.google.com/store/apps/details?id=com.myinfinity',
  mobileGooglePlayQrCode: '/static/images/carrier-android-qr.svg',
  name: 'Carrier',
  portal: 'MyInfinity®',
  product: 'Infinity® System',
  shortProduct: 'Infinity®',
  contactUrl: 'https://www.myinfinitytouch.carrier.com/Contact',
  phone: '1-800-CARRIER',
  symbol: 'ca',
  azureConsumerPortalPageBaseUrl: process.env.REACT_APP_CARRIER_AZURE_CONSUMER_PORTAL_PAGE_URL,
  azureConsumerPortalPageDownloadPath: process.env.REACT_APP_CARRIER_AZURE_CONSUMER_PORTAL_DOWNLOAD_PATH,
  swf: 'MyInfinityWeb',
};

if (process.env.REACT_APP_BRAND_ENV === 'bryant') {
  brand.value = Brand.Bryant; // used to pass into the rest-api
  brand.logo = '/static/images/bryant-logo.png';
  brand.mobileAppLogo = '/static/icons/bryant-mobile-app-logo.png';
  brand.mobileAppName = 'Bryant Home';
  brand.mobileAppStoreUrl = 'https://apps.apple.com/us/app/id1553117891';
  brand.mobileAppAppStoreQrCode = '/static/images/bryant-ios-qr.svg';
  brand.mobileAppGooglePlayUrl = 'https://play.google.com/store/apps/details?id=com.bryant.braynthome';
  brand.mobileGooglePlayQrCode = '/static/images/bryant-android-qr.svg';
  brand.name = 'Bryant';
  brand.portal = 'MyEvolution®';
  brand.product = 'Evolution® System';
  brand.shortProduct = 'Evolution®';
  brand.contactUrl = 'https://www.myevolutionconnex.bryant.com/Contact';
  brand.phone = '1-800-428-4326';
  brand.symbol = 'br';
  brand.azureConsumerPortalPageBaseUrl = process.env.REACT_APP_BRYANT_AZURE_CONSUMER_PORTAL_PAGE_URL;
  brand.azureConsumerPortalPageDownloadPath = process.env.REACT_APP_BRYANT_AZURE_CONSUMER_PORTAL_DOWNLOAD_PATH;
  brand.swf = 'MyEvolutionWeb';
}

if (process.env.REACT_APP_BRAND_ENV === 'ion') {
  brand.value = Brand.Ion; // used to pass into the rest-api
  brand.logo = '/static/images/ion-logo.png';
  brand.mobileAppLogo = '/static/icons/ion-mobile-app-logo.png';
  brand.mobileAppName = 'Ion Home';
  brand.mobileAppStoreUrl = 'https://apps.apple.com/us/app/id1556478005';
  brand.mobileAppAppStoreQrCode = '/static/images/ion-ios-qr.svg';
  brand.mobileAppGooglePlayUrl = 'https://play.google.com/store/apps/details?id=com.ion.ionhome';
  brand.mobileGooglePlayQrCode = '/static/images/ion-android-qr.svg';
  brand.name = 'Ion Comfort';
  brand.portal = 'MyIon™';
  brand.product = 'Ion™ System';
  brand.shortProduct = 'Ion™';
  brand.contactUrl = 'https://www.ioncomfort.com/Contact';
  brand.phone = '(877) 591-8908';
  brand.symbol = 'ion';
  brand.azureConsumerPortalPageBaseUrl = process.env.REACT_APP_ION_AZURE_CONSUMER_PORTAL_PAGE_URL;
  brand.azureConsumerPortalPageDownloadPath = process.env.REACT_APP_ION_AZURE_CONSUMER_PORTAL_DOWNLOAD_PATH;
  brand.swf = 'IonWeb'
}
