import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Oauth1Interceptor } from '@carrier/infinity-oauth1-client';

import useAuth from '../hooks/useAuth';
import { infinityConfig } from '../config';
import { configureAxiosRetryForNonceError } from '../utils/configureAxiosRetryForNonceError';

const oauth1Interceptor = new Oauth1Interceptor(infinityConfig.consumerKey, infinityConfig.consumerSecret);

export const useAxiosInstance = () => {
    const history = useHistory();
    const { logout } = useAuth();

    const commonAxiosInstance = axios.create({ baseURL: infinityConfig.baseURL });

    commonAxiosInstance.interceptors.response.use(
        response => response,
        async error => {
            // Check for 401 Unauthorized response
            if (error.response && error.response.status === 401) {
                await logout();
                history.push('/login');
            }
            // Check for 403 Forbidden Response
            if (error.response && error.response.status === 403) {
                return error.response;
            }
            return Promise.reject((error.response && error.response.data) || 'Something went wrong');
        }
    );
    // apply oauth
    commonAxiosInstance.interceptors.request.use(config =>
        oauth1Interceptor.intercept(config, localStorage.getItem('username'), localStorage.getItem('accessToken'))
    );

    // apply featureset
    commonAxiosInstance.interceptors.request.use(config => {
        config.headers['featureset'] = 'CONSUMER_PORTAL';
        return config;
    });

    configureAxiosRetryForNonceError(commonAxiosInstance);

    return { commonAxiosInstance };
};
