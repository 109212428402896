import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary,
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles, Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import { Theme } from '../../theme';
import { ModalProps } from '../account/AccountView';
import { CARRIER_HELP_DATA } from '../../data/help';

interface HelpModalProps extends ModalProps {
  expandSection?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  question: {
    color: theme.palette.secondary.main
  },
  answer: {
    paddingLeft: theme.spacing(2)
  },
  dialogActions: {
    margin: theme.spacing(1)
  }
}));

const HelpModal: FC<HelpModalProps> = ({
  onClose,
  open,
  expandSection,
  ...rest
}) => {
  const classes = useStyles();

  const helpSections = CARRIER_HELP_DATA;
  const [expandedSections, setExpandedSections] = useState<string[]>([expandSection]);
  const expandedAllSections = expandedSections.length === helpSections.length;

  const handleExpandAllSections = (): void => {
    setExpandedSections(!expandedAllSections
      ? helpSections.map((section) => section.id)
      : []);
  };

  const handleExpandOneSection = (sectionId: string, _event?: React.ChangeEvent<{}>): void => {
    if (!expandedSections.includes(sectionId)) {
      setExpandedSections((prevExpanded) => [...prevExpanded, sectionId]);
    } else {
      setExpandedSections((prevExpanded) => prevExpanded.filter((id) => id !== sectionId));
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const elem = document.getElementById(`${expandSection}-header`);
      console.log('elem:', elem);
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }, [expandSection]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="lg"
      fullWidth
      {...rest}
    >
      <DialogTitle>
        <div className={classes.modalHeader}>
          <Typography>Help</Typography>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={expandedAllSections ? <RemoveCircleOutline /> : <AddCircleOutline />}
            onClick={handleExpandAllSections}
          >
            {expandedAllSections ? 'Collapse' : 'Expand'} All
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        {helpSections.map((section) => {
          const isSectionExpanded = expandedSections.includes(section.id);

          return (
            <Accordion
              key={section.id}
              expanded={isSectionExpanded}
              onClick={(event) => handleExpandOneSection(section.id, event)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${section.id}-content`}
                id={`${section.id}-header`}
              >
                <Typography
                  className={classes.question}
                  dangerouslySetInnerHTML={{
                    __html: section.question
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  className={classes.answer}
                  dangerouslySetInnerHTML={{
                    __html: section.answer
                  }}
                />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          color="primary"
          size="large"
          type="button"
          variant="outlined"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HelpModal;
