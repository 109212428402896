import xml2js from "xml2js";

const builder = new xml2js.Builder({cdata: true, headless: true});

export const xml2Json = async (xml: string): Promise<any> => {
  if (!xml) {
    return null;
  }

  try {
    return await xml2js.parseStringPromise(xml, {explicitArray: false});
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const xml2JsonString = async (xml: string): Promise<any> => {
  if (!xml) {
    return null;
  }

  let obj = await xml2Json(xml);
  return JSON.stringify(obj);
};

export const json2xml = (obj: any): string => {
  if (!obj) {
    return null;
  }
  try {
    return builder.buildObject(obj);
  } catch (e) {
    console.error(e);
    return null;
  }
};
