import { SystemRegisterNotificationPreference } from '../../../types/system-register';
import { Dealer } from '../../../types/system';

export interface InfinityNotificationPreferenceSystem {
  email: string;
  routine: boolean;
  urgent: boolean;
  confirmation: boolean;
  unresponsive?: boolean;
  utilityEvent?: boolean;
}

export interface InfinityNotificationPreferenceDealer {
  routine: boolean;
  urgent: boolean;
  unresponsive?: boolean;
  status: boolean;
  schedule: boolean;
  modifyConfig: boolean;
}

type EmptyObject = {};

export interface InfinityNotificationPreference {
  maxCount: number;
  disconnectHours: number;
  primary: InfinityNotificationPreferenceSystem | EmptyObject;
  secondary?: InfinityNotificationPreferenceSystem | EmptyObject;
  dealer?: InfinityNotificationPreferenceDealer | EmptyObject;
}

export const toSystemNotificationPref = (responseObj): InfinityNotificationPreference => {
  let notificationPref = responseObj?.system_notification_prefs;

  if (!notificationPref) {
    return null;
  }

  let primary = {};
  if (notificationPref.primary) {
    primary = {
      email: notificationPref.primary.email,
      routine: notificationPref.primary.routine === 'true',
      urgent: notificationPref.primary.urgent === 'true',
      confirmation: notificationPref.primary.confirmation === 'true',
      unresponsive: notificationPref.primary.unresponsive === 'true',
      utilityEvent: notificationPref.primary.utilityEvent === 'true'
    };
  }

  let secondary = {};
  if (notificationPref.secondary) {
    secondary = {
      email: notificationPref.secondary.email,
      routine: notificationPref.secondary.routine === 'true',
      urgent: notificationPref.secondary.urgent === 'true',
      confirmation: notificationPref.secondary.confirmation === 'true',
      unresponsive: notificationPref.secondary.unresponsive === 'true',
      utilityEvent: notificationPref.secondary.utilityEvent === 'true'
    };
  }

  let dealer = {};
  if (notificationPref.dealer) {
    dealer = {
      routine: notificationPref.dealer.routine === 'true',
      urgent: notificationPref.dealer.urgent === 'true',
      unresponsive: notificationPref.dealer.unresponsive === 'true',
      status: notificationPref.dealer.status === 'true',
      schedule: notificationPref.dealer.schedule === 'true',
      modifyConfig: notificationPref.dealer.modifyConfig === 'true'
    };
  }

  return {
    maxCount: Number(notificationPref.maxCount),
    disconnectHours: Number(notificationPref.maxCount),
    primary: primary,
    secondary: secondary,
    dealer: dealer
  };
};

export const toUpdatedSystemNotificationPref = (responseObj): InfinityNotificationPreference => {
  let notificationPref = responseObj?.system_notification_prefs;

  if (!notificationPref) {
    return null;
  }

  let primary: InfinityNotificationPreferenceSystem = null;
  if (notificationPref.primary) {
    primary = {
      email: notificationPref.primary.email,
      routine: notificationPref.primary.routine === true,
      urgent: notificationPref.primary.urgent === true,
      confirmation: notificationPref.primary.confirmation === true,
      unresponsive: notificationPref.primary.unresponsive === true,
      utilityEvent: notificationPref.primary.utilityEvent === true
    };
  }

  let secondary: InfinityNotificationPreferenceSystem = null;
  if (notificationPref.secondary) {
    secondary = {
      email: notificationPref.secondary.email,
      routine: notificationPref.secondary.routine === true,
      urgent: notificationPref.secondary.urgent === true,
      confirmation: notificationPref.secondary.confirmation === true,
      unresponsive: notificationPref.secondary.unresponsive === true,
      utilityEvent: notificationPref.secondary.utilityEvent === true
    };
  }

  return {
    maxCount: Number(notificationPref.maxCount),
    disconnectHours: Number(notificationPref.maxCount),
    primary: primary,
    secondary: secondary
  };
};

export const toInfinityNotificationPreference = (
  dealer: Dealer,
  notificationPreference: SystemRegisterNotificationPreference
): InfinityNotificationPreference => {
  let notificationPref: InfinityNotificationPreference = {
    maxCount: notificationPreference.maxNotifications,
    disconnectHours: 24,
    primary: {
      email: notificationPreference.primaryNotifications.email,
      routine: notificationPreference.primaryNotifications.routine,
      urgent: notificationPreference.primaryNotifications.urgent,
      confirmation: notificationPreference.primaryNotifications.emailConfirmation,
      unresponsive: notificationPreference.primaryNotifications.losesContact,
      utilityEvent: notificationPreference.primaryNotifications.utilityEvent
    }
  };

  if (notificationPreference.secondaryNotifications?.email) {
    notificationPref.secondary = {
      email: notificationPreference.secondaryNotifications.email,
      routine: notificationPreference.secondaryNotifications.routine,
      urgent: notificationPreference.secondaryNotifications.urgent,
      confirmation: notificationPreference.secondaryNotifications.emailConfirmation,
      unresponsive: notificationPreference.secondaryNotifications.losesContact,
      utilityEvent: notificationPreference.secondaryNotifications.utilityEvent
    };
  }

  if (dealer.pid && notificationPreference.dealerNotifications) {
    notificationPref.dealer = {
      routine: notificationPreference.dealerNotifications.routine,
      urgent: notificationPreference.dealerNotifications.urgent,
      unresponsive: notificationPreference.dealerNotifications.losesContact,
      status: notificationPreference.dealerNotifications.viewStatus,
      schedule: notificationPreference.dealerNotifications.viewConfig,
      modifyConfig: notificationPreference.dealerNotifications.modifyConfig
    };
  }

  return notificationPref;
};
