import { RegisterSystem } from '../../../types/system-register';
import { InfinityNotificationPreference, toInfinityNotificationPreference } from '../system-notification-pref';
import { UserRegisterRequestDealer, UserRegisterRequestSystem } from '../user-register';

export interface SystemAssociationRequest {
  system: UserRegisterRequestSystem;
  locationId: string;
  promoEmail: string;
  dealer: UserRegisterRequestDealer;
  system_notification_prefs: InfinityNotificationPreference;
}

export interface SystemAssociationRequestXML {
  system_location: SystemAssociationRequest;
}

export const toSystemAssociationRequest = (
  locationId: string,
  registerSystem: RegisterSystem
): SystemAssociationRequest => {
  return {
    locationId,
    system: {
      name: registerSystem.system.name,
      serial: registerSystem.system.serial,
      macAddress: registerSystem.system.mac,
      pin: registerSystem.system.pin
    },
    promoEmail: registerSystem.user.promotions ? 'true' : 'false',
    dealer: {
      pid: registerSystem.dealer.pid,
      name: registerSystem.dealer.name,
      first: registerSystem.dealer.name,
      last: registerSystem.dealer.name,
      street1: registerSystem.dealer.street,
      street2: registerSystem.dealer.unit,
      city: registerSystem.dealer.city,
      state: registerSystem.dealer.state,
      postal: registerSystem.dealer.zip,
      country: registerSystem.dealer.country,
      phone: registerSystem.dealer.phone
    },
    system_notification_prefs: toInfinityNotificationPreference(
      registerSystem.dealer,
      registerSystem.notificationPreference
    )
  };
};

export const toSystemAssociationRequestXML = (
  locationId: string,
  registerSystem: RegisterSystem
): SystemAssociationRequestXML => {
  return {
    system_location: toSystemAssociationRequest(locationId, registerSystem)
  };
};

export interface SystemDisassociationRequestXML {
  system_location: {
    serialNumber: string;
    locationId: string;
  };
}

export const toSystemDisassociationRequestXML = (
  locationId: string,
  serialNumber: string
): SystemDisassociationRequestXML => {
  return {
    system_location: {
      serialNumber,
      locationId
    }
  };
};
