import type {FC, ReactNode} from 'react';
import React, {createContext, useEffect, useReducer} from 'react';
import type {User} from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import {UserRegister} from "../types/user-register";
import { registerNewUser } from "../lib/InfinityClient";
import { useInfinityClient } from 'src/hooks/useInfinityClient';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  method: 'Infinity',
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  register: (userRegister: UserRegister) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const isValidToken = (tokenLastUpdated: string): boolean => {
  if (!tokenLastUpdated) {
    return false;
  }

  // 4 hours in seconds
  const tokenLifeTime = 4 * 60 * 60;
  const currentTime = Date.now() / 1000;
  const tokenLastUpdatedSec = new Date(tokenLastUpdated).getTime() / 1000;

  let isValid = (tokenLastUpdatedSec + tokenLifeTime > currentTime);
  return isValid;
};

const setSession = (username: string, accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('username', username);
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('tokenLastUpdated', new Date().toISOString());
  } else {
    localStorage.removeItem('username');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('tokenLastUpdated');
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const {isAuthenticated, user} = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const {user} = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const {user} = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return {...state};
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'Infinity',
  login: () => Promise.resolve(),
  logout: () => {
  },
  register: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = ({children}) => {
  const { refreshToken } = useInfinityClient();
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (username: string, password: string) => {
    let accessToken = await refreshToken(username, password);
    // set user name from response
    setSession(accessToken.username, accessToken.accessToken);

    let user: User = {
      email: username,
      id: accessToken.username,
      name: accessToken.username
    }

    // setSession(responseData);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: user
      }
    });
  };

  const logout = () => {
    setSession(null, null);
    dispatch({type: 'LOGOUT'});
  };

  const register = async (userRegister: UserRegister) => {
    await registerNewUser(userRegister);
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const username = window.localStorage.getItem('username');
        const accessToken = window.localStorage.getItem('accessToken');
        const tokenLastUpdated = window.localStorage.getItem('tokenLastUpdated');

        if (username && accessToken && isValidToken(tokenLastUpdated)) {
          setSession(username, accessToken);

          // const response = await axios.get<{ user: User; }>('/api/account/me');
          // const {user} = response.data;

          // console.log(`${username}:${accessToken}`)

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user: {
                email: "n/a",
                id: username,
                name: username
              }
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen/>;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Infinity',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
