import { getIdFromAtomLinkObject, toArray } from '../utils';
import { Notification, NotificationType } from '../../../types/system';

export const getNotificationType = (type: string | number): NotificationType => {
  if (String(type) === '1') {
    return 'warning';
  }

  if (String(type) === '2' || String(type) === '4') {
    return 'error';
  }

  return 'info';
};

export const toNotification = (responseObj): Notification => {
  return {
    id: getIdFromAtomLinkObject(responseObj),
    message: responseObj.message,
    timestamp: new Date(responseObj.timestamp),
    read: responseObj.userConfirmed === 'true',
    type: getNotificationType(responseObj.type)
  };
};

export const toSystemNotifications = (responseObj): Notification[] => {
  let notifications = toArray(responseObj?.notifications?.notification);

  return notifications.filter(note => note.userDeleted !== 'true').map(note => toNotification(note));
};
