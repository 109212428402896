import React, {Fragment, lazy, Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import GuestGuard from 'src/components/GuestGuard';
import StyleGuideLayout from './layouts/StyleGuideLayout';
import AuthGuard from './components/AuthGuard';
import ConfigToolLayout from "./layouts/ConfigToolLayout";

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    path: ['/Infinity*', '/Evolution*', '/Ion*','/Contact*','/AboutLegal*','/Content/*','/bundles/*'],
    component: lazy(() => import('src/components/LegacyAppRedirect'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/oauth2/authorize',
    component: lazy(() => import('src/components/Oauth2Redirect'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/Account/Register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/Account/ForgotPassword',
    component: lazy(() => import('src/views/auth/ForgotUsernamePasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/Account/ResetPassword',
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: ConfigToolLayout,
    path: '/config-tool',
    component: lazy(() => import('src/views/config-tool'))
  },
  {
    path: '*',
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        guard: AuthGuard,
        path: '/',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        guard: AuthGuard,
        path: '/account',
        component: lazy(() => import('src/views/account/AccountView'))
      }
    ]
  },
  {
    path: '/style-guide',
    layout: StyleGuideLayout,
    routes: [
      {
        exact: true,
        path: '/style-guide',
        component: () => <Redirect to="/style-guide/welcome" />
      },
      {
        exact: true,
        path: '/style-guide/welcome',
        component: lazy(() => import('src/views/style-guide/WelcomeView'))
      },
      {
        exact: true,
        path: '/style-guide/typography',
        component: lazy(() => import('src/views/style-guide/TypographyView'))
      },
      {
        exact: true,
        path: '/style-guide/colors',
        component: lazy(() => import('src/views/style-guide/ColorsView'))
      },
      {
        exact: true,
        path: '/style-guide/input',
        component: lazy(() => import('src/views/style-guide/InputView'))
      },
      {
        exact: true,
        path: '/style-guide/buttons',
        component: lazy(() => import('src/views/style-guide/ButtonsView'))
      }
    ]
  }
];

export default routes;
