export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  CARRIER_DARK: 'CARRIER_DARK'
};

export const HOME_HUMIDITY_MAPPER = {
  '1': 5,
  '2': 10,
  '3': 15,
  '4': 20,
  '5': 25,
  '6': 30,
  '7': 35,
  '8': 40,
  '9': 45,
};

export const AWAY_HUMIDITY_MAPPER = {
  '1': 5,
  '2': 10,
  '3': 15,
  '4': 20,
  '5': 25,
  '6': 30,
  '7': 35,
  '8': 40,
  '9': 45,
};
