import { UserRegister } from '../../../types/user-register';
import { Brand, brand } from "../../../config";
import { InfinityNotificationPreference, toInfinityNotificationPreference } from '../system-notification-pref';


export interface UserRegisterRequestUser {
  email: string;
  username: string;
  password?: string;
  first: string;
  last: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  country?: string;
  postal?: string;
  phone1Ext?: string;
  phone1?: string;
  phone2?: string;
  phone2Ext?: string;
  updatedDateTime?: string;
  accountActive?: boolean;
  acceptedTermsOfServiceDateTime?: string;
  promoEmail?: string;
  allowCommunication?: boolean;
}

export interface UserRegisterRequestDealer {
  pid?: number | string;
  name: string;
  first: string;
  last: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  country: string;
  postal: string;
  phone: string;
  phoneExt?: string;
}

export interface UserRegisterRequestSystem {
  pin: string;
  name: string;
  serial: string;
  macAddress: string;
}

export interface UserRegisterRequestLocation {
  name: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  postal: string;
  country: string;
}

export interface UserRegisterRequest {
  system: UserRegisterRequestSystem;
  user: UserRegisterRequestUser;
  dealer: UserRegisterRequestDealer;
  location: UserRegisterRequestLocation;
  system_notification_prefs: InfinityNotificationPreference;
  brand: Brand;
}

export interface UserRegisterRequestXml {
  register: UserRegisterRequest;
}

export const toUserRegisterRequest = (userRegister: UserRegister): UserRegisterRequest => {
  let notificationPref: InfinityNotificationPreference = toInfinityNotificationPreference(
    userRegister.dealer,
    userRegister.notificationPreference
  );

  return {
    system: {
      pin: userRegister.system.pin,
      name: userRegister.system.name,
      serial: userRegister.system.serial,
      macAddress: userRegister.system.mac
    },
    user: {
      username: userRegister.user.username,
      password: userRegister.user.password,
      email: userRegister.user.email,
      first: userRegister.user.firstName,
      last: userRegister.user.lastName,
      address1: userRegister.user.street,
      address2: userRegister.user.unit,
      city: userRegister.user.city,
      state: userRegister.user.state,
      postal: userRegister.user.zip,
      country: userRegister.user.country,
      phone1: userRegister.user.mainPhone,
      phone1Ext: userRegister.user.mainExt,
      phone2: userRegister.user.secondaryPhone,
      phone2Ext: userRegister.user.secondaryExt,
      promoEmail: userRegister.user.promotions ? 'true' : 'false'
    },
    dealer: {
      pid: userRegister.dealer.pid,
      name: userRegister.dealer.name,
      first: userRegister.dealer.name,
      last: userRegister.dealer.name,
      street1: userRegister.dealer.street,
      street2: userRegister.dealer.unit,
      city: userRegister.dealer.city,
      state: userRegister.dealer.state,
      postal: userRegister.dealer.zip,
      country: userRegister.dealer.country,
      phone: userRegister.dealer.phone
    },
    location: {
      name: userRegister.location.name,
      street1: userRegister.location.street,
      street2: userRegister.location.unit,
      city: userRegister.location.city,
      state: userRegister.location.state,
      postal: userRegister.location.zip,
      country: userRegister.location.country
    },
    system_notification_prefs: notificationPref,
    brand: brand.value
  };
};

export const toUserRegisterRequestXml = (userRegister: UserRegister): UserRegisterRequestXml => {
  let obj = toUserRegisterRequest(userRegister);
  return {
    register: obj
  };
};
