import { getIdFromAtomLinkObject } from '../utils';
import { SystemLocation } from '../../../types/system';
import { toLocation } from '../location';

export const toLocationIds = (responseObj: any): string[] => {
  let locations = responseObj?.locations?.location;

  if (!locations) {
    return [];
  }

  if (!Array.isArray(locations)) {
    locations = [locations];
  }

  let locationIds: string[] = locations.map(loc => getIdFromAtomLinkObject(loc)).filter(item => !!item);

  return locationIds;
};

export const toSystemLocations = (responseObj: any): SystemLocation[] => {
  let locations = responseObj?.locations?.location;

  if (!locations) {
    return [];
  }

  if (!Array.isArray(locations)) {
    locations = [locations];
  }

  let systemLocations: SystemLocation[] = locations.map(loc => toLocation(loc)).filter(item => !!item);

  return systemLocations;
};
