import { SystemLocation } from '../../../types/system';
import { getIdFromAtomLinkObject, toArray } from '../utils';

export interface LocationRequest {
  name: string;
  street1: string;
  street2?: string;
  city: string;
  state: string;
  postal: string;
  country: string;
}

export interface LocationXmlRequest {
  location: LocationRequest;
}

export const toLocation = (locationObj: any): SystemLocation => {
  let location = locationObj?.location ?? locationObj;

  if (!location) {
    return null;
  }

  let responseSystems = [];

  let systems: Array<any> = toArray(location.systems?.system);

  responseSystems = systems
    .map(sys => getIdFromAtomLinkObject(sys))
    .filter(item => !!item)
    .map(sn => {
      return { serial: sn };
    });

  return {
    id: getIdFromAtomLinkObject(location),
    name: location.name,
    street: location.street1,
    unit: location.street2,
    city: location.city,
    state: location.state,
    zip: location.postal,
    country: location.country,
    systems: responseSystems
  };
};
