import _ from 'lodash';
import {
  createMuiTheme,
  responsiveFontSizes
} from '@material-ui/core';
import type { Theme as MuiTheme } from '@material-ui/core/styles/createMuiTheme';
import type { Shadows as MuiShadows } from '@material-ui/core/styles/shadows';
import type {
  Palette as MuiPalette,
  TypeBackground as MuiTypeBackground
} from '@material-ui/core/styles/createPalette';
import { THEMES } from 'src/constants';
import { ShapeOptions } from '@material-ui/core/styles/shape';
import { strongShadows } from './shadows';
import typography from './typography';

interface TypeBackground extends MuiTypeBackground {
  dark: string;
}

interface Palette extends MuiPalette {
  background: TypeBackground;
}

export interface Theme extends MuiTheme {
  name: string;
  palette: Palette;
}

type Direction = 'ltr' | 'rtl';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  theme?: string;
}

interface ThemeOptions {
  name?: string;
  direction?: Direction;
  typography?: Record<string, any>;
  overrides?: Record<string, any>;
  palette?: Record<string, any>;
  shadows?: MuiShadows;
  shape?: ShapeOptions;
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'rgba(97, 97, 97, 0.96)'
      }
    }
  }
};

const themesOptions: ThemeOptions[] = [
  {
    name: THEMES.CARRIER_DARK,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(246, 246, 245, 0.4)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#272D33',
        dark: '#0F1A23',
        paper: '#272D33'
      },
      primary: {
        main: '#f6f6f5'
      },
      secondary: {
        main: '#3399CC'
      },
      error: {
        main: '#f44336'
      },
      warning: {
        main: '#f4cb36'
      },
      success: {
        main: '#3fa92a'
      },
      text: {
        primary: '#F6F6F5',
        secondary: '#B1B1B1',
        error: '#f44336',
        disabled: 'rgba(246, 246, 245, 0.4)'
      }
    },
    shadows: strongShadows,
    typography: {
      button: {
        textTransform: 'none'
      }
    },
    shape: {
      borderRadius: 12
    }
  }
];

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge(
      {},
      baseOptions,
      themeOptions,
      { direction: config.direction }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme as Theme;
}
