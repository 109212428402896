import { Brand, brand } from '../config';
import { json2xml, xml2Json } from '../utils/xml';
import { toSystemLocations } from '../lib/infinity-rest-api/user-locations';
import { LocationRequest, LocationXmlRequest, toLocation } from '../lib/infinity-rest-api/location';
import { Dealer, Notification, SystemConfig, SystemLocation, SystemProfile } from '../types/system';
import { SystemStatus, toSystemConfig, toSystemStatus } from '../lib/infinity-rest-api/system-status';
import { toSystemDealer, toSystemDealerRequest } from '../lib/infinity-rest-api/system-dealer';
import { toSystemNotifications } from '../lib/infinity-rest-api/system-notification';
import {
    InfinityNotificationPreference,
    toSystemNotificationPref
} from '../lib/infinity-rest-api/system-notification-pref';
import {
    SystemAssociationRequestXML,
    SystemDisassociationRequestXML,
    toSystemAssociationRequestXML,
    toSystemDisassociationRequestXML
} from '../lib/infinity-rest-api/system-registration';
import { UserAccount } from '../types/user-account';
import {
    RefreshTokenResponse,
    toRefreshTokenResponse,
    toUpdateUserRequest,
    toUserAccount
} from '../lib/infinity-rest-api/user';
import { toUserApps } from '../lib/infinity-rest-api/user-apps';
import { RegisterSystem } from '../types/system-register';
import { ThirdPartyApp } from '../types/third-party-app';
import { toSystemProfile } from '../lib/infinity-rest-api/system-profile';

import {
    SystemPreferenceRequest,
    SystemPreferenceRequestXML,
    toSystemPreferenceRequestXML
} from '../lib/infinity-rest-api/system-preference';
import { SystemPreferenceDTO } from '../types/system-preference';
import { useAxiosInstance } from './useAxiosInstance';
import { error_status_regex, status_code_match } from '../data/RegexConstants';

const X_WWW_FORM_URLENCODED_HEADERS_CONFIG = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        featureset: 'CONSUMER_PORTAL'
    }
};

const toXWwwFormEncodedPayload = (obj: any): string => {
    const xmlStr = json2xml(obj);
    return 'data=' + encodeURIComponent(xmlStr);
};
export interface InfinityUserAuthenticationInfoXML {
    credentials: {
        username: string;
        password: string;
        params: {
            brand: Brand;
        };
    };
}

const handleInfinityError = async e => {
    if (e.response?.data) {
        const responseObj = await xml2Json(e.response?.data);

        const errorMessage = responseObj?.error?.message;
        if (errorMessage) {
            throw new Error(errorMessage);
        }
    }

    throw e;
};

export enum IsSystemInfoRegistrationResponse {
    SUCCESS,
    INVALID_SERIAL_NUMBER_OR_HAS_NOT_CONNECT,
    INVALID_MAC_PIN,
    ALREADY_REGISTERED
}

export const useInfinityClient = () => {
    const { commonAxiosInstance } = useAxiosInstance();

    const refreshToken = async (username: string, password: string): Promise<RefreshTokenResponse> => {
        const credentials: InfinityUserAuthenticationInfoXML = {
            credentials: {
                username,
                password,
                params: {
                    brand: brand.value
                }
            }
        };

        const data = toXWwwFormEncodedPayload(credentials);

        const response = await commonAxiosInstance.post(
            '/users/authenticated',
            data,
            X_WWW_FORM_URLENCODED_HEADERS_CONFIG
        );
        const responseObj = await xml2Json(response.data);

    if (responseObj?.error) {
      const error = responseObj?.error;
      const match = (error.message).match(error_status_regex);
      if ((match && match[1].startsWith('4'))|| status_code_match.test(error.status)) {
        error.message = "errorStartsWithStatusCode4";
      } 

            switch (error?.message) {
                case 'Failed':
                    throw new Error('The username or password provided is incorrect.');
                case 'NotFound':
                    throw new Error('The username is not found.');
                case 'LockedOut':
                    throw new Error(
                        'Your account has been locked because you have exceeded the number of log on attempts. To unlock your account, please reset your password.'
                    );
                case 'NotActive':
                    throw new Error('Your account is not Active. Please call Support.');
                case 'errorStartsWithStatusCode4':
                    throw new Error('Something went wrong. Please try again later!');
                default:
                    throw new Error(`Unknown Error: ${error}`);
            }
        }

        const tokenResponse = toRefreshTokenResponse(responseObj);

        if (!tokenResponse) {
            throw new Error('Invalid Username or Password');
        }

        return tokenResponse;
    };

    const getUser = async (username: string): Promise<UserAccount> => {
        if (!username) {
            throw new Error('username is empty');
        }

        const response = await commonAxiosInstance.get(`/users/${username}`);
        const responseObj = await xml2Json(response.data);

        const userAccount = toUserAccount(responseObj);
        return userAccount;
    };

    const deleteUser = async (username: string, reason?: string): Promise<void> => {
        const requestBody = {
            deactivate: {
                reason
            }
        };
        const data = toXWwwFormEncodedPayload(requestBody);
        await commonAxiosInstance.post(`/users/${username}/deactivate`, data, X_WWW_FORM_URLENCODED_HEADERS_CONFIG);
    };

    const updateUserInfo = async (userInfo): Promise<void> => {
        const userUpdateInfoRequest = toUpdateUserRequest(userInfo);
        const data = toXWwwFormEncodedPayload(userUpdateInfoRequest);

        await commonAxiosInstance.post(
            `/users/${userUpdateInfoRequest.user.username}`,
            data,
            X_WWW_FORM_URLENCODED_HEADERS_CONFIG
        );
    };

    const changeUserPassword = async (username, passwordData): Promise<void> => {
        const passwordChangeRequest = {
            passwordChange: {
                oldPassword: passwordData.oldPassword,
                newPassword: passwordData.newPassword
            }
        };
        const data = toXWwwFormEncodedPayload(passwordChangeRequest);

        try {
            await commonAxiosInstance.post(
                `/users/${username}/changePassword`,
                data,
                X_WWW_FORM_URLENCODED_HEADERS_CONFIG
            );
        } catch (e) {
            const responseObj = await xml2Json(e);
            if (responseObj?.error) {
                const errorMessage = responseObj?.error?.message;
                if (errorMessage) {
                    throw new Error(errorMessage);
                }
            }
            throw e;
        }
    };

    const getUserApps = async (username: string): Promise<Array<ThirdPartyApp>> => {
        const response = await commonAxiosInstance.get(`/users/${username}/apps`);
        const responseObj = await xml2Json(response.data);

        const userApps = toUserApps(responseObj);
        return userApps;
    };

    const revokeUserApp = async (username: string, appId: string): Promise<Array<ThirdPartyApp>> => {
        const response = await commonAxiosInstance.delete(`/users/${username}/apps/${appId}`);
        const responseObj = await xml2Json(response.data);

        return responseObj;
    };

    const getUserLocations = async (username: string): Promise<Array<SystemLocation>> => {
        const response = await commonAxiosInstance.get(`/users/${username}/locations`);
        const responseObj = await xml2Json(response.data);

        const systemLocations = toSystemLocations(responseObj);
        return systemLocations;
    };

    const getLocation = async (locationId: string): Promise<SystemLocation> => {
        const response = await commonAxiosInstance.get(`/locations/${locationId}`);
        const responseObj = await xml2Json(response.data);

        const location = toLocation(responseObj);
        return location;
    };

    const updateLocation = async (locationId: string, location: LocationRequest): Promise<void> => {
        const xmlRequest: LocationXmlRequest = {
            location: location
        };
        const data = toXWwwFormEncodedPayload(xmlRequest);

        await commonAxiosInstance.post(`/locations/${locationId}`, data, X_WWW_FORM_URLENCODED_HEADERS_CONFIG);
    };

    const createUserLocation = async (location: LocationRequest): Promise<void> => {
        const xmlRequest: LocationXmlRequest = {
            location: location
        };
        const data = toXWwwFormEncodedPayload(xmlRequest);

        const username = localStorage.getItem('username');
        if (!username) {
            throw new Error('username not found');
        }

        await commonAxiosInstance.post(`/users/${username}/location`, data, X_WWW_FORM_URLENCODED_HEADERS_CONFIG);
    };

    const deleteLocation = async (locationId: string): Promise<void> => {
        if (!locationId) {
            throw new Error('locationId not found');
        }
        try {
            await commonAxiosInstance.delete(`/locations/${locationId}`, X_WWW_FORM_URLENCODED_HEADERS_CONFIG);
        } catch (e) {
            const errResObj = await xml2Json(e);
            const errorMessage = errResObj?.error?.message;
            if (errorMessage) {
                throw new Error(errorMessage);
            }
        }
    };

    const updateSystemName = async (systemData): Promise<void> => {
        const updateSystemNameRequest = {
            system: {
                profile: {
                    name: systemData.systemName
                }
            }
        };
        const data = toXWwwFormEncodedPayload(updateSystemNameRequest);

        await commonAxiosInstance.post(
            `/systems/${systemData.serialNumber}`,
            data,
            X_WWW_FORM_URLENCODED_HEADERS_CONFIG
        );
    };

    const getSystemStatus = async (serialNumber: string): Promise<SystemStatus> => {
        try {
            const response = await commonAxiosInstance.get(`/systems/${serialNumber}/status`);
            const responseObj = await xml2Json(response.data);

            const systemStatus = toSystemStatus(responseObj);
            return systemStatus;
        } catch (e) {
            const status = e.response?.status;
            if (status === 404) {
                return toSystemStatus(null);
            }
            throw e;
        }
    };

    const getSystemConfig = async (serialNumber: string): Promise<SystemConfig> => {
        const response = await commonAxiosInstance.get(`/systems/${serialNumber}/config`);
        const responseObj = await xml2Json(response.data);

        const systemConfig = toSystemConfig(responseObj);
        return systemConfig;
    };

    const getSystemDealer = async (serialNumber: string): Promise<Dealer> => {
        const response = await commonAxiosInstance.get(`/systems/${serialNumber}/dealer`);
        const responseObj = await xml2Json(response.data);

        const systemDealer = toSystemDealer(responseObj);
        return systemDealer;
    };

    const getSystemNotifications = async (serialNumber: string): Promise<Notification[]> => {
        const response = await commonAxiosInstance.get(`/systems/${serialNumber}/notifications`);
        const responseObj = await xml2Json(response.data);
        const systemNotifications = toSystemNotifications(responseObj);
        return systemNotifications;
    };

    const getSystemProfile = async (serialNumber: string): Promise<SystemProfile> => {
        const response = await commonAxiosInstance.get(`/systems/${serialNumber}/profile`);
        const responseObj = await xml2Json(response.data);

        const systemProfile = toSystemProfile(responseObj);
        return systemProfile;
    };

    const deleteSystemNotification = async (notificationId: string): Promise<void> => {
        await commonAxiosInstance.delete(`/notifications/${notificationId}`, X_WWW_FORM_URLENCODED_HEADERS_CONFIG);
    };

    const getSystemNotificationPref = async (serialNumber: string): Promise<InfinityNotificationPreference> => {
        const response = await commonAxiosInstance.get(`/systems/${serialNumber}/notification_prefs`);
        const responseObj = await xml2Json(response.data);

        const notificationPref = toSystemNotificationPref(responseObj);
        return notificationPref;
    };

    const updateSystemDealer = async (dealer, serialNumber): Promise<void> => {
        const updateSystemDealerRequest = toSystemDealerRequest(dealer);
        const data = toXWwwFormEncodedPayload(updateSystemDealerRequest);

        await commonAxiosInstance.post(`/systems/${serialNumber}/dealer`, data, X_WWW_FORM_URLENCODED_HEADERS_CONFIG);
    };

    const associateSystemLocation = async (
        username: string,
        locationId: string,
        registerSystem: RegisterSystem
    ): Promise<void> => {
        const xmlRequest: SystemAssociationRequestXML = toSystemAssociationRequestXML(locationId, registerSystem);
        const data = toXWwwFormEncodedPayload(xmlRequest);

        try {
            await commonAxiosInstance.post(
                `/users/${username}/system_location`,
                data,
                X_WWW_FORM_URLENCODED_HEADERS_CONFIG
            );
        } catch (e) {
            await handleInfinityError(e);
        }
    };

    const disassociateSystemLocation = async (
        username: string,
        locationId: string,
        serialNumber: string
    ): Promise<void> => {
        const xmlRequest: SystemDisassociationRequestXML = toSystemDisassociationRequestXML(locationId, serialNumber);
        const data = toXWwwFormEncodedPayload(xmlRequest);

        await commonAxiosInstance.delete(`/users/${username}/system_location`, {
            ...X_WWW_FORM_URLENCODED_HEADERS_CONFIG,
            data: data
        });
    };

    const updateSystemPreference = async (
        serialNumber: string,
        systemPreferenceDto: SystemPreferenceDTO
    ): Promise<SystemPreferenceRequest> => {
        const xmlRequest: SystemPreferenceRequestXML = toSystemPreferenceRequestXML(systemPreferenceDto);
        const data = toXWwwFormEncodedPayload(xmlRequest);

        try {
            await commonAxiosInstance.post(
                `/systems/${serialNumber}/system_preference`,
                data,
                X_WWW_FORM_URLENCODED_HEADERS_CONFIG
            );
            return xmlRequest.system_preference;
        } catch (e) {
            await handleInfinityError(e);
        }
    };

    const activateUserDevices = async (username: string) => {
        try {
            await commonAxiosInstance.post(`/users/${username}/activateSystems`);
        } catch (e) {
            await handleInfinityError(e);
        }
    };

    const activateDevice = async (serialNumber: string) => {
        try {
            await commonAxiosInstance.post(`/systems/${serialNumber}/activate`);
        } catch (e) {
            await handleInfinityError(e);
        }
    };

    return {
        refreshToken,
        getUser,
        deleteUser,
        updateUserInfo,
        changeUserPassword,
        getUserApps,
        revokeUserApp,
        getUserLocations,
        getLocation,
        updateLocation,
        createUserLocation,
        deleteLocation,
        updateSystemName,
        getSystemStatus,
        getSystemConfig,
        getSystemDealer,
        getSystemNotifications,
        getSystemProfile,
        deleteSystemNotification,
        getSystemNotificationPref,
        updateSystemDealer,
        associateSystemLocation,
        disassociateSystemLocation,
        updateSystemPreference,
        activateUserDevices,
        activateDevice
    };
};
