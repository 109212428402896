import React, { FC } from 'react';
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle, Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Theme } from '../../../theme';
import { ModalProps } from '../../account/AccountView';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 3000
  },
  h1: {
    marginBottom: theme.spacing(4)
  },
  h2: {
    marginBottom: theme.spacing(2)
  },
  h3: {
    marginBottom: theme.spacing(2)
  },
  p: {
    marginBottom: theme.spacing(2)
  },
  list: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4)
  },
  link: {
    color: theme.palette.secondary.main
  },
  dialogActions: {
    margin: theme.spacing(1)
  }
}));

const PrivacyPolicyModal: FC<ModalProps> = ({
  onClose,
  open,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="lg"
      fullWidth
      className={classes.root}
      {...rest}
    >
      <DialogTitle>
        <Typography>Privacy Policy</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h1" className={classes.h1}>Privacy Notice</Typography>

        <Typography className={classes.p}>Carrier Corporation and its parent, subsidiaries and affiliates (collectively, <strong
          className="important">“Carrier”</strong>), are committed to protecting the privacy of visitors to our
          websites (<strong className="important">“Website”</strong>) and users of our mobile applications (<strong
            className="important">“Apps”</strong>) and connected thermostats, controls, cameras and other products
          (collectively, <strong className="important">“Connected Products”</strong>). It is important that our users
          understand how we collect, use and disclose Personal Information (as defined below). Carrier has implemented
          technical, administrative and physical measures to safeguard any personal information that we may collect.
        </Typography>
        <Typography className={classes.p}>This Privacy Notice describes our practices related to Personal Information collected through Carrier
          Websites, Apps, and Connected Products, in each case unless there is a separate privacy notice for a
          particular website, mobile application or product.</Typography>

        <Typography variant="h2" className={classes.h2}>WHAT PERSONAL INFORMATION DOES CARRIER COLLECT? HOW DOES CARRIER COLLECT SUCH INFORMATION?</Typography>

        <Typography className={classes.p}><strong className="important">“Personal Information”</strong> is information relating to an identified or
          identifiable natural person.</Typography>
        <Typography className={classes.p}>Categories of Personal Information Collected</Typography>
        <Typography className={classes.list}>
          <ul>
            <li>Contact Information, such as name, physical address, phone number and email address, which you or your
              contractor provides.
            </li>
            <li>Basic Product Information, such as information about the product you are registering and the
              person/company that installed or maintains it, recommended products and other products that may be of
              interest to you, and setup information you or your contractor provides.
            </li>
            <li>Automatically Collected Information from our Websites and Apps, such as user interaction data, device
              identifiers, IP address, MAC address, log files and location data (including geofencing, if available with
              your product and turned on). This may also include information about your home, such as square footage and
              year built. Please see “How Does Carrier Use Cookies and Other Tracking Technologies?” and “How May
              Carrier Use Location Data?” below for more information.
            </li>
            <li>Automatically Collected Information from your Connected Products, such as environmental data from a
              thermostat or control’s sensors and video from cameras, heating and cooling usage information from
              connected HVAC equipment (including statuses and schedules), and other technical information from your
              product and connected equipment. Please see “Why Does Carrier Collect Your Personal Information?” below.
            </li>
          </ul>
        </Typography>
        <Typography className={classes.p}>We might need to collect other information for a specific form, feature, or other service that you may use
          or request. The content of such information will vary depending on the method of collection and the specific
          purpose(s) for which the information is being collected.</Typography>
        <Typography className={classes.p}>Your mobile or internet service providers may have a conflicting privacy notice or policy that permits them
          to capture, use and/or retain your Personal Information when you visit the Websites or use the Apps, but
          Carrier is not responsible for and does not control how other parties may collect your Personal Information
          when you access the Websites or Apps.</Typography>

        <Typography variant="h2" className={classes.h2}>WHY DOES CARRIER COLLECT YOUR PERSONAL INFORMATION?</Typography>

        <Typography className={classes.p}>Carrier may collect, use and process your Personal Information in order to provide you with services,
          products or information that you request, conduct basic business operations and otherwise in accordance with
          this Privacy Notice, as you direct or with your consent.</Typography>
        <Typography className={classes.p}>If we collect other information for a specific form, feature, or other service that you may use or request,
          we will use the information for a purpose or purposes relevant to the particular form, feature or service.
          For information you provide, you will have the opportunity to decide whether or not to provide it. If you
          choose not to provide any of the Personal Information requested, Carrier may be unable to complete your
          transaction, or provide the information, services or products you have requested. For information that is
          automatically collected, you can choose not to visit our Websites or use our Apps or Connected Products.</Typography>
        <Typography className={classes.p}>Except where used in support of a contract with you, to protect against fraud or investigate suspected or
          actual illegal activity or to fulfill or ensure compliance with a legal obligation, law enforcement
          investigation or regulatory request, our use of your Personal Information – whether contact information,
          basic product information, automatically collected information from our Websites and Apps or automatically
          collected information from your Connected Products – will be only for legitimate business interests as set
          out in this Privacy Notice.</Typography>
        <Typography className={classes.p}>We do not sell your Personal Information except with your explicit, up-front consent.</Typography>
        <Typography className={classes.p}>Contact, Basic Product and Automatically Collected Information from our Websites and
          Apps</Typography>
        <Typography className={classes.p}>Our purposes for collecting contact information, basic product information and automatically collected
          information from our Websites and Apps are to:</Typography>
        <Typography className={classes.list}>
          <ul>
            <li>Make our products, services, Websites and Apps available to you, including to authenticate you as a user
              and allow you access to certain areas of our Websites, Apps or social media sites.
            </li>
            <li>Improve our products, services, Websites and Apps, develop new offerings, provide customized services
              and content relevant to your specific needs and interests and, with respect to de-identified, aggregated
              information, for benchmarking, development of best practices and other research, statistical and marketing
              purposes.
            </li>
            <li>Analyze your behavior on our Websites and Apps, improve and personalize user experience and better
              prepare future content and features based upon your interests and those of our general user population.
            </li>
            <li>Communicate with you regarding product registration, product notifications, service reminders, requests
              to rate your HVAC contractor and review your products, and otherwise to market products and services that
              may be relevant to your HVAC needs.
            </li>
          </ul>
        </Typography>
        <Typography className={classes.p}>Automatically Collected Information from Connected Products</Typography>
        <Typography className={classes.p}>Our purposes for collecting information from Connected Products are to:</Typography>
        <Typography className={classes.list}>
          <ul>
            <li>
              Make the product and related Websites and/or Apps available to you in accordance with the applicable End
              User License Agreement. For example:
              <ul>
                <li>Environmental data from the product’s sensors, such as current temperature in the room, is collected
                  to help your thermostat or control keep you comfortable when you are home and save energy when you are
                  away.
                </li>
                <li>Video is transmitted from cameras so you can monitor and record the environment within your camera’s
                  view. Carrier does not store or see your video or images.
                </li>
                <li>Heating and cooling usage, equipment status and schedules you set are collected to make features
                  such as usage history, service notifications and scheduling available to you, and to help improve
                  performance, diagnostics and service by your HVAC contractor.
                </li>
                <li>Other technical information such as product model and serial number, software version and battery
                  charge level are collected to improve your experience over time and help troubleshoot any problem you
                  may encounter with your product.
                </li>
              </ul>
            </li>
            <li>Improve our products and services, develop new offerings and, with respect to de-identified, aggregated
              information, for benchmarking, development of best practices and other research, statistical and marketing
              purposes.
            </li>
          </ul>
        </Typography>
        <Typography className={classes.p}>If your Connected Product is installed in a jurisdiction that requires consent to data collection, by using
          the product, you consent to the data collection and usage outlined in this Privacy Notice and the applicable
          End User License Agreement.</Typography>

        <Typography variant="h2" className={classes.h2}>WHERE IS PERSONAL INFORMATION STORED?</Typography>

        <Typography className={classes.p}>Because Carrier is a global company with locations in many different countries, we may transfer your
          information from one legal entity to another or from one country to another within the Carrier group of
          companies in order to accomplish the purposes listed above. These countries include, at a minimum, the
          United States, the member states of the European Union, the United Kingdom, Switzerland, Canada, and other
          countries, including some in Asia. We will transfer your Personal Information consistent with applicable
          legal requirements and only to the extent necessary for the purposes set out above. </Typography>
        <Typography className={classes.p}>Carrier engages third-party service providers to provide data hosting, maintenance, management, support and
          other services. These third-party providers may be located in other countries than where you reside. Carrier
          relies on available legal mechanisms to enable the legal transfer of Personal Information across borders
          (where required by applicable law). To the extent that Carrier relies on the Standard Contractual Clauses
          (also called the Model Clauses) or <Link href="http://www.utc.com/pages/privacy.aspx" className={classes.link} target="_blank" rel="noopener">Binding Corporate Rules</Link> to authorize
          transfer (where required by applicable law), Carrier will comply with those requirements, including where
          there may be a conflict between those requirements and this Privacy Notice.</Typography>

        <Typography variant="h2" className={classes.h2}>DOES CARRIER USE YOUR PERSONAL INFORMATION TO CONTACT YOU?</Typography>

        <Typography className={classes.p}>Carrier may use the Personal Information you provide to contact you about products, services, promotions,
          special offers, surveys, and other information that may be of interest to you. If you prefer not to receive
          such communications, please use the “unsubscribe” function within the communication, Website or App, or let
          us know by sending an email to <Link href="mailto:HVACprivacy@carrier.com" className={classes.link} target="_blank" rel="noopener">HVACprivacy@carrier.com</Link> with “UNSUBSCRIBE”
          as the subject line. Please note that if you unsubscribe from marketing communications, you may continue to
          receive communications about your account or transactions with us.</Typography>

        <Typography variant="h2" className={classes.h2}>DOES CARRIER SHARE THE INFORMATION IT COLLECTS WITH THIRD PARTIES?</Typography>

        <Typography className={classes.p}>Carrier may share your Personal Information with our affiliated companies, such as companies in Carrier’s
          group of companies, and/or companies in which Carrier has control, through either direct or indirect
          ownership, for the purposes set out above. When we do so, your Personal Information will be shared in
          accordance with our Binding Corporate Rules.</Typography>
        <Typography className={classes.list}>
          <ul>
            <li>In addition, Carrier will provide access to or share Personal Information on an as-needed basis with
              third parties, such as trusted service providers, consultants and contractors who are granted access to
              Carrier facilities and systems or which provide services to Carrier, and with government agencies and
              others as required by law. In particular, Carrier will only share your Personal Information outside
              Carrier to: Service providers, dealers, distributors, agents or contractors with whom Carrier works or who
              service your equipment, such as your HVAC contractor. Carrier is committed to contractually restricting
              third parties from using or disclosing the information except as necessary to perform services on our or
              your behalf or to comply with legal requirements;
            </li>
            <li>Connect you with a local HVAC contractor, for example, when you use the Build a System or Find a Dealer
              features on our Website. If you request an appointment, the dealer you select may contact you to schedule
              an appointment and for their own direct marketing purposes;
            </li>
            <li>Allow you to access or control your Connected Product using a third-party app when you enable such
              access from within our App, or otherwise at your request. If you choose to do this, the third-party app
              you enable may use your information in accordance with its own privacy policy and practices, and not this
              Privacy Notice;
            </li>
            <li>Transfer automatically collected information from our Websites and Apps to online and app service
              providers such as Google, Facebook and others, for example when you click a link or open an app
              redirecting you to the third-party provider;
            </li>
            <li>Comply with legal obligations, including but not limited to complying with tax and regulatory
              obligations, sharing data with labor/trade unions and works councils, and responding to a court proceeding
              or a legitimate legal request from law enforcement authorities or other government regulators;
            </li>
            <li>Investigate suspected or actual illegal activity;</li>
            <li>Prevent physical harm or financial loss; or</li>
            <li>Support the sale or transfer of all or a portion of our business or assets (including through
              bankruptcy).
            </li>
          </ul>
        </Typography>
        <Typography className={classes.p}>Your Personal Information will also be maintained and processed by our service providers in the United
          States, the member states of the European Union, the United Kingdom, Switzerland, Canada, Asia, and in other
          jurisdictions within an appropriate legal and contractual framework.</Typography>

        <Typography variant="h2" className={classes.h2}>REQUESTING AN APPOINTMENT</Typography>

        <Typography className={classes.p}>A user may have the option to request an appointment with a contractor online. To submit an online
          appointment request, you are asked to provide Personal Information to receive a response or some other
          service. The Personal Information requested includes your name, mobile phone number, email address, and home
          address. The collection of information will be transparent to you – you will be asked for it and will have
          the opportunity to decide whether or not to provide it. If you choose not to provide any of the Personal
          Information requested, Carrier may be unable to complete the transaction you have requested. Users who
          submit an appointment request will receive emails and text (SMS) messages about their appointment including
          acknowledgement, confirmations, status updates, and ratings and review requests. To opt-out reply with text
          STOP. Text (SMS) message &amp; data rates may apply. Carrier will use the home address you provide to search
          our product registration database for previously registered equipment and provide a list of this equipment,
          warranty coverage, and warranty service history to the contactor you selected. The contractor uses this
          information to provide you with enhanced levels of technical support.</Typography>

        <Typography variant="h2" className={classes.h2}>RATINGS AND REVIEWS</Typography>

        <Typography className={classes.p}>You may be asked to provide ratings or reviews of our products and services, as well as those of your HVAC
          contractor. If posted publicly to our Websites or Apps, or on third-party platforms, your review could
          potentially identify you. Our general guidelines on ratings and reviews are as follows:</Typography>
        <Typography className={classes.p}>You may not impersonate someone else (e.g., adopt the identity of a celebrity or your next-door neighbor)
          or create a review for anyone other than yourself. You alone are responsible for any material you may submit
          via our Websites (“Your Content”), and once published, it cannot always be withdrawn. You assume all risks
          associated with Your Content, including anyone’s reliance on its quality, accuracy, or reliability, or any
          disclosure by you of information in Your Content that makes you personally identifiable. You represent that
          you own, or have the necessary permissions to use and authorize the use of Your Content as described herein.
          You may not imply that Your Content is in any way sponsored or endorsed by Carrier. You may expose yourself
          to liability if, for example, Your Content contains material that is false, intentionally misleading, or
          defamatory; violates any third-party right, including any copyright, trademark, patent, trade secret, moral
          right, privacy right, right of publicity, or any other intellectual property or proprietary right; contains
          material that is unlawful, including illegal hate speech or pornography; exploits or otherwise harms minors;
          or violates or advocates the violation of any law or regulation. Content submitted by others (including any
          that may have been created by users employed or contracted by Carrier) does not necessarily reflect the
          opinion of Carrier. We reserve the right to remove, screen, edit, or reinstate such content from time to
          time at our sole discretion for any reason or no reason, and without notice to you. For example, we may
          remove a review if we believe it violates the General Review Guidelines below. We have no obligation to
          retain or provide you with copies of Your Content, nor do we guarantee any confidentiality with respect to
          Your Content. We may use Your Content in a number of different ways, including publicly displaying it,
          reformatting it, incorporating it into advertisements and other works (including social media), creating
          derivative works from it, promoting it, distributing it, and allowing others to do the same in connection
          with their own websites and media platforms (“Other Media”). As such, you hereby irrevocably grant us
          world-wide, perpetual, non-exclusive, royalty-free, assignable, sublicensable, transferable rights to use
          Your Content for any purpose. Please note that you also irrevocably grant the users of our Websites and any
          Other Media the right to access Your Content in connection with their use of the Website and any Other
          Media. Finally, you irrevocably waive, and cause to be waived, against Carrier and its users any claims and
          assertions of moral rights or attribution with respect to Your Content. By “use”; we mean use, copy,
          publicly perform and display, reproduce, distribute, modify, translate, remove, analyze, commercialize, and
          prepare derivative works of Your Content.</Typography>
        <Typography className={classes.p}>General Review Guidelines</Typography>
        <Typography className={classes.list}>
          <ul>
            <li>Inappropriate content: Threats, harassment, lewdness, hate speech, and other displays of bigotry are not
              permitted.
            </li>
            <li>Conflicts of interest: Your contributions should be unbiased and objective. For example, you shouldn’t
              write reviews of your own business or employer, your friends’ or relatives’ business, your peers or
              competitors in your industry, or businesses in your networking group. Business owners should not ask
              customers to write reviews.
            </li>
            <li>Promotional content: Unless you are using your business account to add content to your business’s
              profile page, you shouldn’t submit promotional content.
            </li>
            <li>Relevance: Please make sure your contributions are relevant and appropriate to the forum. For example,
              reviews should not be used to discuss a business’s employment practices, political ideologies,
              extraordinary circumstances, or other matters that do not address the core of the consumer experience.
            </li>
            <li>Privacy: Don’t publicize other people’s private information. Please don’t post close-up photos or videos
              of other individuals without their permission, and please don’t post other people’s full names unless
              you’re referring to service providers who are commonly identified by or commonly share their own full
              names.
            </li>
            <li>Intellectual property: All content must be your own.</li>
            <li>Demanding payment: Beyond simply asking for a refund to remedy a bad experience, you should not use
              removing or posting your review as a way to extract payment from a business, regardless of whether you’ve
              been a customer.
            </li>
          </ul>
        </Typography>

        <Typography variant="h2" className={classes.h2}>HOW DOES CARRIER SECURE PERSONAL INFORMATION?</Typography>

        <Typography className={classes.p}>Carrier is committed to protecting the security and integrity of Personal Information. Carrier has adopted
          reasonable technical, physical, and administrative procedures to safeguard your Personal Information,
          including reasonable security features in its Connected Products. However, due to the nature of Internet
          communications, we cannot guarantee or warrant that transmission or storage of your Personal Information is
          secure.</Typography>

        <Typography variant="h2" className={classes.h2}>HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION?</Typography>

        <Typography className={classes.p}>The Personal Information you provide to Carrier is only kept for as long as it is reasonably necessary for
          the purposes for which it was collected, taking into account our need to comply with contractual
          obligations, resolve customer service issues, comply with legal requirements and provide new or improved
          services to users. This means that we may retain your Personal Information for a reasonable period after you
          stopped using our Website, App or Connected Product. After this period, your Personal Information will be
          deleted from all systems of Carrier without notice.</Typography>

        <Typography variant="h2" className={classes.h2}>HOW CAN YOU CORRECT, CHANGE OR DELETE YOUR INFORMATION HELD BY CARRIER?</Typography>

        <Typography className={classes.p}>You may request to access, update, correct, change, or delete your Personal Information at any time.
          Carrier will use reasonable efforts to timely update and/or remove your Personal Information. To protect the
          user’s privacy and security, Carrier will take steps to verify the user’s identity before provided access or
          making any requested change. To access, update, correct, change, or delete your Personal Information, or to
          ask questions or raise concerns regarding data protection and privacy, please send an email to <Link
            href="mailto:HVACprivacy@carrier.com" className={classes.link} target="_blank" rel="noopener">HVACprivacy@carrier.com</Link>. Some of our
          Websites, Apps, and social media accounts allow you to make corrections directly without need to further
          contact Carrier.</Typography>
        <Typography className={classes.p}>Please note that while we will assist you in protecting your Personal Information as outlined in this
          Privacy Notice, it is your responsibility to protect your passwords and other access credentials from
          others.</Typography>

        <Typography variant="h2" className={classes.h2}>HOW CAN YOU CHANGE YOUR APP OR CONNECTED PRODUCT PRIVACY PREFERENCES?</Typography>

        <Typography className={classes.p}>Depending on your particular product and App, you can go to the privacy preference settings within the App
          or contact <Link href="mailto:HVACprivacy@carrier.com" className={classes.link} target="_blank" rel="noopener">HVACprivacy@carrier.com</Link>.
          If you use a third-party app, you will need to review the privacy settings within that app or contact the
          applicable third party.</Typography>

        <Typography variant="h2" className={classes.h2}>WHAT SHOULD YOU UNDERSTAND ABOUT THIRD-PARTY LINKS, FEATURES AND SOLUTIONS THAT MAY BE USED ON OUR
          WEBSITES AND WITHIN OUR APPS AND EMAILS?</Typography>

        <Typography className={classes.p}>In some instances, Carrier may provide links to and features from non-Carrier controlled websites, and may
          integrate third-party solutions within our Website, Apps and emails to deliver a more streamlined
          experience. Carrier does not control such third-party websites and solutions (whether linked or integrated
          within the Website), however, and is not responsible for the content or the privacy practices employed by
          third parties. Furthermore, this Privacy Notice does not govern information collected about you by third
          parties. The privacy policies of the following third parties featured or integrated within our Website, Apps
          and emails can be found by clicking the links attached to their respective names below:</Typography>
        <Typography className={classes.p}>Social Network Sites and Other Links</Typography>
        <Typography className={classes.p}><Link href="https://www.ecobee.com/legal/use/" className={classes.link} target="_blank" rel="noopener">ecobee</Link>, <Link
          href="https://www.facebook.com/policy.php" className={classes.link} target="_blank" rel="noopener">Facebook</Link>, <Link
          href="https://www.linkedin.com/legal/privacy-policy" className={classes.link} target="_blank" rel="noopener">LinkedIn</Link>, <Link
          href="https://www.surveymonkey.com/mp/legal/privacy-policy/?ut_source=footer"
          className={classes.link} target="_blank" rel="noopener">SurveyMonkey</Link>, <Link href="https://twitter.com/privacy"
                                                         className={classes.link} target="_blank" rel="noopener">Twitter</Link>, <Link
          href="https://www.youtube.com/about/policies/" className={classes.link} target="_blank" rel="noopener">YouTube</Link>, <Link
          href="https://www.yelp.com/static?p=privacy" className={classes.link} target="_blank" rel="noopener">Yelp</Link></Typography>
        <Typography className={classes.p}>Integrated Solutions</Typography>
        <Typography className={classes.p}><Link href="https://www.appzi.com/privacy/" className={classes.link} target="_blank" rel="noopener">Appzi</Link>, <Link
          href="https://www.corelogic.com/privacy.aspx" className={classes.link} target="_blank" rel="noopener">CoreLogic</Link>, <Link
          href="https://www.crazyegg.com/privacy" className={classes.link} target="_blank" rel="noopener">Crazy Egg</Link>, <Link
          href="https://www.ecobee.com/legal/use/" className={classes.link} target="_blank" rel="noopener">ecobee</Link>, <Link
          href="https://www.ecorebates.com/privacy" className={classes.link} target="_blank" rel="noopener">Ecorebates</Link>, <Link
          href="https://www.evergage.com/privacy-policy/" className={classes.link} target="_blank" rel="noopener">Evergage</Link>, <Link
          href="https://www.google.com/policies/privacy/" className={classes.link} target="_blank" rel="noopener">Google</Link>, <Link
          href="https://heap.io/privacy" className={classes.link} target="_blank" rel="noopener">Heap</Link>, <Link
          href="https://www.salesforce.com/company/privacy/" className={classes.link} target="_blank" rel="noopener">Salesforce</Link></Typography>
        <Typography className={classes.p}>In addition, you may choose to enable access to Connected Product using a third-party app, which may then
          access your Personal Information and use it in accordance with the third party’s own privacy policies and
          practices. Please see “Does Carrier Share the Information It Collects?” above.</Typography>

        <Typography variant="h2" className={classes.h2}>HOW DOES CARRIER USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</Typography>

        <Typography variant="h3" className={classes.h3}>Cookies</Typography>
        <Typography className={classes.p}>Carrier may use cookies on this Website. Cookies are small text files sent to and stored on users’
          computers. We use cookies to allow the Website to recognize repeat users, facilitate users’ access to the
          Website, and allow the Website to compile aggregate data that will allow content improvements. Cookies do
          not damage users’ computers or files. If you do not want cookies to be accessible by this or any other
          Carrier Website, you should adjust the settings on your browser program to deny or disable the use of
          cookies. However, denying or disabling cookies or similar technology may prevent you from accessing some of
          our content or using some of the features on the Website. Carrier may also use cookies and similar
          technology placed by one of our business or advertising partners to enable Carrier to learn which
          advertisements bring users to our Website.</Typography>
        <Typography className={classes.p}>In addition, Carrier monitors user traffic patterns throughout its Websites according to a user's domain
          name, browser type, date and time of access, pages viewed and other Website interactions. Our Web servers
          collect the domain names but not the email addresses of visitors. This information is collected in order to
          measure the number of visitors to our Website and to determine which areas of the Website users find useful
          based upon the amount of traffic to particular areas and other interactions with the Website. Carrier uses
          this information to enhance users’ experience at the Website and to better prepare future content based on
          the interests of users.</Typography>
        <Typography className={classes.p}>Our Web servers automatically collect the domain names but not the email addresses of visitors. However, if
          you provide an email address or other Personal Information to us, we may tie your Personal Information with
          information collected via cookies.</Typography>
        <Typography variant="h3" className={classes.h3}>Web Beacons</Typography>
        <Typography className={classes.p}>This Website may also use web beacons. A web beacon is usually a pixel on a <Link
          href="http://www.webopedia.com/TERM/W/Web_site.html" className={classes.link} target="_blank" rel="noopener">website</Link> that can be used
          to track whether a user has visited a particular website to deliver targeted advertising. Web beacons are
          used in combination with <Link href="http://www.webopedia.com/TERM/C/cookie.html"
                                      className={classes.link} target="_blank" rel="noopener">cookies</Link>, which means that, if you turn off your
          browser's cookies, the web beacons will not be able to track your activity. The web beacon will still count
          as a website visit, but your unique information will not be recorded.</Typography>
        <Typography className={classes.p}>Information collected by web beacons may include IP address information (see below). Web beacon information
          is also used for:</Typography>
        <Typography className={classes.list}>
          <ul>
            <li>Advertising and email auditing, and reporting;</li>
            <li>Personalization;</li>
            <li>Site traffic reporting; and</li>
            <li>Unique visitor counts.</li>
          </ul>
        </Typography>
        <Typography className={classes.p}>If you provide an email address or other Personal Information to us, we may tie your Personal Information
          with information collected via web beacons.</Typography>
        <Typography variant="h3" className={classes.h3}>IP Addresses</Typography>
        <Typography className={classes.p}>An Internet Protocol (IP) address is a number that is used by computers on the network to identify your
          computer every time you log on to the Internet.</Typography>
        <Typography className={classes.p}>Carrier may collect and keep track of IP addresses to, among other things:</Typography>
        <Typography className={classes.list}>
          <ul>
            <li>Maintain Website safety and security;</li>
            <li>Restrict access to our Websites to certain users;</li>
            <li>Troubleshoot technical issues; and</li>
            <li>Better understand how Carrier Websites are used.</li>
          </ul>
        </Typography>
        <Typography className={classes.p}>If you provide an email address or other Personal Information to us, we may tie your Personal Information
          with your IP address.</Typography>
        <Typography variant="h3" className={classes.h3}>Log Files</Typography>
        <Typography className={classes.p}>Carrier (or third parties on behalf of Carrier) may collect information in the form of log files that are
          recording Website activities and statistics about web users' habits.</Typography>
        <Typography className={classes.p}>Log files are used for internal purposes only. By using log files, Carrier can constantly improve and
          customize its Website and Apps.</Typography>
        <Typography className={classes.p}>The entries help us gather, among other things:</Typography>
        <Typography className={classes.list}>
          <ul>
            <li>Internal marketing and demographic studies;</li>
            <li>A user’s browser type and operating system;</li>
            <li>Information about a user’s session (such as the URL, the date and time our Website was visited and which
              pages were viewed on our Website and for how long); and
            </li>
            <li>Other similar navigational or click-stream data.</li>
          </ul>
        </Typography>
        <Typography className={classes.p}>If you provide an email address or other Personal Information to us, we may tie your Personal Information
          with information collected via log files.</Typography>
        <Typography variant="h3" className={classes.h3}>Social Network Information</Typography>
        <Typography className={classes.p}>Social network information is any information that you permit a third-party social network to share with
          us. Social network information includes any information that is part of your profile on a third-party social
          network. Such social networks may include, but are not limited to, Twitter, Facebook, YouTube.</Typography>
        <Typography className={classes.p}>To find out how your information from a social network may be obtained by us (or other third parties),
          please go on the settings page of the relevant social network. Furthermore, you acknowledge that we may use
          your Personal Information to match information with a social network for advertising purposes and otherwise
          tie your Personal Information with your social network information consistent with this Privacy Notice.</Typography>
        <Typography className={classes.p}>Carrier cannot make assurances about the security of information that you share with third parties,
          including information you share with social media sites.</Typography>
        <Typography className={classes.p}>For more information about cookies and other tracking technologies, click <Link
          href="http://www.allaboutcookies.org/" className={classes.link} target="_blank" rel="noopener">here</Link>.</Typography>

        <Typography variant="h2" className={classes.h2}>HOW MAY CARRIER USE LOCATION DATA?</Typography>

        <Typography className={classes.p}>Location data can generally be broken into two categories: static data, such as your current home address
          or zip code, and dynamic data, such as IP address, MAC address, GPS or similar technologies.</Typography>
        <Typography className={classes.p}>Carrier uses static location data, such as your zip code, in the same ways as any other contact
          information. </Typography>
        <Typography className={classes.p}>Carrier uses dynamic location data, such as IP or MAC address, as described above in the “Why Does Carrier
          Collect Your Personal Information” and “How Does Carrier Use Cookies and Other Tracking Technologies”
          sections. </Typography>
        <Typography className={classes.p}>Thermostat/control schedules and other presence data settings, such as whether your HVAC equipment is set
          to home or away, are used in the same ways as other automatically collected information from Connected
          Products. </Typography>
        <Typography className={classes.p}>In addition, if geofencing is available with your product and turned on, dynamic location data is used so
          your thermostat or control can adjust the temperature based on whether you (specifically, your mobile
          device) is within or outside the vicinity surrounding your home. </Typography>

        <Typography variant="h2" className={classes.h2}>WHAT ADDITIONAL INFORMATION SHOULD SPECIFIC USERS KNOW?</Typography>

        <Typography className={classes.p}><strong className="important">Parents, Guardians, and Children:</strong> Our Apps and Websites are intended
          for visitors who are at least 18 years of age, or the age of majority in their jurisdiction of residence.
          Carrier does not knowingly solicit information from, or market products or services to, children. If you do
          not meet the age requirements set out above, please do not enter your Personal Information on our Websites
          or Apps.</Typography>
        <Typography className={classes.p}><strong className="important">Users from California:</strong></Typography>
        <Typography className={classes.p}>If you are a resident of California, effective January 1, 2020, you have certain rights under the
          California Consumer Privacy Act of 2018 (“CCPA”), including the right to: </Typography>
        <Typography className={classes.list}>
          <ol className="lower-alpha">
            <li>know what categories of personal information about you we have collected and used, including how the
              personal information was collected and the purpose for the collection and use;
            </li>
            <li>know what categories of personal information are being sold or disclosed to third parties and, if sold
              or disclosed, the categories of third parties receiving it;
            </li>
            <li>access specific personal information collected about you; and</li>
            <li>request that your personal information be deleted.</li>
          </ol>
        </Typography>
        <Typography className={classes.p}>Requests to exercise rights under the CCPA are subject to verification by Carrier and are also subject to
          certain restrictions provided by the CCPA. We will not discriminate against California residents for
          exercising any of their applicable CCPA rights. While California residents have the right to opt out of the
          sale of their personal information under the CCPA, Carrier does not sell personal information to third
          parties except with your explicit, up-front consent. If our practices change, we will update this Privacy
          Notice and take any necessary action to comply with applicable law.</Typography>
        <Typography className={classes.p}>California residents who wish to exercise their CCPA rights can contact Carrier at <Link
          href="mailto:HVACprivacy@carrier.com" className={classes.link} target="_blank" rel="noopener">HVACprivacy@carrier.com</Link> or use the
          webform located <Link href="https://www.ccs.utc.com/legal/privacy-notice/" className={classes.link} target="_blank" rel="noopener">here</Link>.
          You can also contact us at 1-833-617-0050. When contacting us to exercise your rights, please put
          “California Privacy Rights” in the subject and body of your request and describe the nature of your request.
        </Typography>
        <Typography className={classes.p}>Annually California residents may request and obtain information that Carrier shared with other businesses
          for their own direct marketing use within the prior calendar year (as defined by California’s “Shine the
          Light Law”). If applicable, this information would include a list of the categories of Personal Information
          that was shared and the names and addresses of all third parties with which Carrier shared this information
          in the immediately preceding calendar year. To obtain this information, please send an email message to <Link
            href="mailto:HVACprivacy@carrier.com" className={classes.link} target="_blank" rel="noopener">HVACprivacy@carrier.com</Link> with
          “California Shine the Light Privacy Request” in the subject line as well as in the body of your message.</Typography>
        <Typography className={classes.p}><strong className="important">Users from the European Union and other countries with privacy
          laws:</strong> You have the right to lodge a complaint with your national or state data protection
          authority, which may also be known as a supervisory authority. You also have the right to: (i) request
          access to and correction or erasure of your Personal Information; (ii) seek restrictions on; or (iii) object
          to the processing of certain Personal Information, and seek data portability under certain circumstances. To
          contact Carrier about a request to access, correct, erase, object or seek restrictions or portability,
          please use the contact methods indicated at the end of this Privacy Notice.</Typography>
        <Typography className={classes.p}><strong className="important">How might Carrier change this Privacy Notice?</strong></Typography>
        <Typography className={classes.p}>As Carrier expands and improves its Websites, Apps and Connected Products, or as legal requirements change,
          we may need to update this Privacy Notice. This Privacy Notice may be modified from time to time without
          prior notice. The date of the latest version will be identified at the bottom of the notice. We encourage
          you to review this Privacy Notice on a regular basis for any changes.</Typography>

        <Typography variant="h2" className={classes.h2}>HOW CAN YOU CONTACT CARRIER?</Typography>

        <Typography className={classes.p}>If you have any comments or questions about this Privacy Notice or about Carrier’s privacy practices in
          general, or if you wish to access, update, correct, change or delete your Personal Information, please
          email <Link href="mailto:HVACprivacy@carrier.com" className={classes.link} target="_blank" rel="noopener">HVACprivacy@carrier.com</Link>.</Typography>
        <Typography className={classes.p}>In the event that you are located in a country that is governed by the General Data Protection Regulation
          and would like to contact the local Data Protection Officer, please note that in your email and your inquiry
          will be directed to the appropriate person. If you wish to opt-out from sharing your Personal Information
          with us or have your Personal Information be removed, you should follow <Link
            href="mailto:HVACprivacy@carrier.com" className={classes.link} target="_blank" rel="noopener">this link</Link>.</Typography>
        <Typography className={classes.p}>Last updated on January 1, 2020</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          color="primary"
          size="large"
          type="button"
          variant="outlined"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrivacyPolicyModal;
