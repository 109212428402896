import { brand } from 'src/config';
import { HelpSection } from '../types/help';

const DOWNLOADS_URL =  brand.azureConsumerPortalPageBaseUrl + brand.azureConsumerPortalPageDownloadPath;

export const CARRIER_HELP_DATA: HelpSection[] = [
  {
    id: 'setup-wap',
    question: 'How do I setup the Wireless Access Point? <small>(SYSTXCCITW01 and SYSTXCCITW01‑A models only)</small>',
    answer: 'The Wireless Access Point is pre-configured from the factory and does not require any changes. The cable should be connected to your existing internet connection via the CAT5 cable provided in the box. It should be connected to the WAN port on the TP‑LINK<sup>®</sup> router and to an open LAN port on your existing router.'
  },
  {
    id: 'existing-router',
    question: 'Will I be able to use my existing wireless router to connect to Infinity<sup>®</sup> System control? <small>(SYSTXCCITW01 and SYSTXCCITW01‑A models only)</small>',
    answer: 'It is not recommended that you try connecting your Infinity<sup>®</sup> System control to any router other than the TP‑LINK<sup>®</sup> router that has been provided with the Infinity<sup>®</sup> System control.'
  },
  {
    id: 'other-devices',
    question: 'Can I connect other devices at my home using the Wireless Access Point? <small>(SYSTXCCITW01 and SYSTXCCITW01‑A models only)</small>',
    answer: 'You should not use the Wireless Access Point as a connection for anything but the Infinity<sup>®</sup> System control. Do not connect any device to the LAN ports on the back of the TP‑LINK<sup>®</sup> Wireless Access Point.'
  },
  {
    id: 'admin-wap',
    question: 'Why can’t I access the administrator functionality on the Wireless Access Point? <small>(SYSTXCCITW01 and SYSTXCCITW01‑A models only)</small>',
    answer: 'The Wireless Access Point has been configured to specific settings to ensure reliable connectivity to your Infinity<sup>®</sup> System control. To maintain proper functionality and reliability, the administrator functions are not available to the home owner.'
  },
  {
    id: 'monthly-fee',
    question: 'Is there a monthly fee for remote access functionality?',
    answer: 'No, the remote access functionality is available free of charge.'
  },
  {
    id: 'setup-wireless',
    question: 'How do I setup wireless connection on the Infinity<sup>®</sup> System control? <small>(SYSTXCCITW01 and SYSTXCCITW01‑A models only)</small>',
    answer: 'Go to the Menu, touch the Down Arrow, and select Wireless. Touch the Enabled button, and then touch Setup a wi-fi Connection. Next, touch “scan for available access points”. You will be looking for a network name called “MyHVACXXXXXX”, where the X’s are the last 6 digits of the Wireless Access Point’s MAC address. Next, touch the white blank to enter the encryption key. The encryption key is printed on a sticker located on the bottom of the Wireless Access Point. You will see a message on the control telling you if the Infinity<sup>®</sup> System control has connected.'
  },
  {
    id: 'test-connection',
    question: 'How do I test if the Infinity<sup>®</sup> System control is connected to the Wireless Access Point? <small>(SYSTXCCITW01, SYSTXCCITW01‑A and SYSTXCCITC01‑A models only)</small>',
    answer: 'Go to the Menu, touch the Down Arrow, and select Wireless. Next, touch View Remote Access Status to see the Network Status and the MyInfinity<sup>®</sup> Server status. The Network status will display Connected if the Infinity<sup>®</sup> System control is connected to the local Wireless Access Point.'
  },
  {
    id: 'mobile-availability',
    question: 'What mobile apps are available?',
    answer: 'There are applications available for the iPad<sup>®</sup>, iPhone<sup>®</sup>, Android™, PC and Mac<sup>®</sup>.'
  },
  {
    id: 'mobile-requirements',
    question: 'What are the version requirements for the apps?',
    answer: 'Version requirements for smart phones are shown in the app download area of the App Store<sup class="trademark">SM</sup> and Google Play.'
  },
  {
    id: 'ipod-touch',
    question: 'Can I use my iPod touch<sup>®</sup> with the MyInfinity<sup class="trademark">SM</sup> app?',
    answer: 'Yes, the Apple<sup>®</sup> mobile app is compatible with the iPod touch<sup>®</sup> (3rd generation and above).'
  },
  {
    id: 'pc-requirements',
    question: 'What are the version requirements for the PC and Mac apps?',
    answer: 'Adobe<sup>®</sup> AIR<sup>®</sup> is required for the PC and Mac apps. Go to <a style="color: #3399CC;" href="http://get.adobe.com/air/" class="external-link">http://get.adobe.com/air/</a> to download Adobe AIR and to view the system requirements.'
  },
  {
    id: 'desktop-update-error',
    question: 'I’m having trouble updating the MyInfinity<sup class="trademark">SM</sup> desktop app…',
    answer: '<p>You may see the following screen when you try to update the MyInfinity<sup class="trademark">SM</sup> desktop app:</p>\n' +
      '        <p><img src="/static/images/desktop-app-update-error.gif" alt="Sorry, an error has occurred. This application cannot be installed because this installer has been mis-configured. Please contact the application author for assistance."></p>\n' +
      '        <p>To fix this issue you will need to uninstall your current version of the MyInfinity desktop app, and then install the latest version.</p>\n' +
      '        <h4>Uninstall Directions for Windows Vista, Windows 7, and Windows 8 Users</h4>\n' +
      '        <ol>\n' +
      '            <li>Open the Programs and Features screen by clicking the <strong class="label">Start</strong> button, clicking <strong class="label">Control Panel</strong>, clicking <strong class="label">Programs</strong>, and then clicking <strong class="label">Programs and Features</strong>.</li>\n' +
      '            <li>Select <strong class="label">MyInfinity</strong> and then click <strong class="label">Uninstall</strong>.</li>\n' +
      '        </ol>\n' +
      '        <h4>Uninstall Directions for Windows XP Users</h4>\n' +
      '        <ol>\n' +
      '            <li>Open the Currently Installed Programs screen by clicking the <strong class="label">Start</strong> button, clicking <strong class="label">Control Panel</strong>, and then clicking <strong class="label">Add or Remove Programs</strong>.</li>\n' +
      '            <li>Select <strong class="label">MyInfinity</strong> and then click <strong class="label">Remove</strong>.</li>\n' +
      '        </ol>\n' +
      '        <h4>Uninstall Directions for Mac OS X Users</h4>\n' +
      '        <ol>\n' +
      '            <li>Open a Finder window, and click on <strong class="label">Applications</strong>. If you installed the MyInfinity desktop app in a folder other than the default, open that folder instead.</li>\n' +
      '            <li>Ctrl + click or right click on <strong class="label">MyInfinity</strong> and then click <strong class="label">Move to Trash</strong>. OS X may prompt you to enter your password to confirm that you want to move MyInfinity to the Trash.</li>\n' +
      '        </ol>\n' +
      '        <p>Once you have uninstalled your current version of the MyInfinity desktop app, install the latest version by doing the following:</p>\n' +
      '        <ol>\n' +
      `            <li>Return to the <a style="color: #3399CC;" href=${DOWNLOADS_URL} target="_blank">MyInfinity Downloads</a> page.</li>\n` +
      '            <li>Click <strong class="label">Download MyInfinity</strong>.</li>\n' +
      '            <li>Once download is complete, run the program to install.</li>\n' +
      '        </ol>'
  },
  {
    id: 'mobile-download',
    question: 'How do I download the mobile applications?',
    answer: `Visit <a style="color: #3399CC;" class="parent-window-link" href=${DOWNLOADS_URL} target="_blank">the Downloads page</a>. There are instructions below the banner with links explaining which software to download for your particular device.`
  },
  {
    id: 'weather-forecast',
    question: 'How do I see weather forecast? <small>(SYSTXCCITW01, SYSTXCCITW01‑A and SYSTXCCITC01‑A models only)</small>',
    answer: 'The easiest way to view the weather forecast is to touch the weather icon on the Home screen as displayed when you walk up to the control. If you are in the menu and weather screen, there is an option to view the forecast there as well.'
  },
  {
    id: 'recover-password',
    question: 'I forgot my password, how do I recover it?',
    answer: 'Visit <a style="color: #3399CC;" class="parent-window-link" href="/Account/ForgotPassword">the Forgot Password page</a>. You will see a screen that will ask for your username or e-mail address. Submitting this information will request an e-mail that will be sent to you with instructions to reset or update your password.'
  },
  {
    id: 'update-address',
    question: 'How do I update my address information?',
    answer: '<p>Before you update any of your account information, you will need to log in to your account.</p>\n' +
      '        <h4>To change the address where your system is located…</h4>\n' +
      '        <ol>\n' +
      '            <li>Click on <a style="color: #3399CC;" class="parent-window-link" href="/account">My Locations</a>.</li>\n' +
      '            <li>Click on the <strong class="label">Edit Location Information</strong> link at the top of the area with the gray background.</li>\n' +
      '            <li>When you are finished making changes, click on <strong class="label">Update</strong>, in the bottom right-hand corner of the page.</li>\n' +
      '        </ol>\n' +
      '        <h4>To change the address where you may be contacted…</h4>\n' +
      '        <ol>\n' +
      '            <li>Click on <a style="color: #3399CC;" class="parent-window-link" href="/account">My Account</a>.</li>\n' +
      '            <li>Click on the <strong class="label">Edit Contact Information</strong> link.</li>\n' +
      '            <li>When you are finished making changes, click on <strong class="label">Update</strong>, in the bottom right-hand corner of the page.</li>\n' +
      '        </ol>'
  },
  {
    id: 'select-dealer',
    question: 'How do I select my dealer?',
    answer: 'Log in to your account. Click on <a style="color: #3399CC;" class="parent-window-link" href="/account">My Locations</a>. In the area with the gray background, at the bottom of the screen, there is a label for Dealer. Below this is a link “Select a Carrier Expert<sup class="trademark">SM</sup>” or “Confirm your dealer”. Click this link. On the next page, click on the link to Select a dealer or Confirm your dealer. In the window that pops up, enter your Postal Code and search radius and click Search. After a few moments, a list should appear. Select the desired dealer from the list. When you are finished making changes, click on Update, in the bottom right-hand corner of the page.'
  },
  {
    id: 'dealer-privacy',
    question: 'What information can my dealer see about my system?',
    answer: 'Log in to your account. Click on <a style="color: #3399CC;" class="parent-window-link" href="/account">My Locations</a>. In the area with the gray background, at the bottom of the screen, there is a label for Notification Preferences. Click on the link below labeled Edit Notification Preferences. The next screen will provide you with dealer details, if you’ve selected a dealer, and at the bottom of the screen, you may check the boxes for the information you’d like your dealer to be able to view. When you are finished making changes, click on Update, in the bottom right-hand corner of the page.'
  },
  {
    id: 'ventilator-settings',
    question: 'I don’t see ventilator settings on my remote access account or in the apps, why?',
    answer: 'Ventilator settings are not currently available with the current version of the remote access application. It will be added in the future.'
  },
  {
    id: 'email-notifications',
    question: 'How do I change the type of notifications I receive via e-mail?',
    answer: 'Log in to your account. Click on <a style="color: #3399CC;" class="parent-window-link" href="/account">My Locations</a>. In the area with the gray background, at the bottom of the screen, there is a label for Notification Preferences. Click on the link below labeled Edit Notification Preferences. On the next screen, select the desired notification types by checking the boxes. When you are finished making changes, click on Update, in the bottom right-hand corner of the page.'
  },
  {
    id: 'change-notifications-email',
    question: 'How do I change the e-mail where notifications are sent?',
    answer: 'Log in to your account. Click on <a style="color: #3399CC;" class="parent-window-link" href="/account">My Locations</a>. In the area with the gray background, at the bottom of the screen, there is a label for Notification Preferences. Click on the link below labeled Edit Notification Preferences. On the next screen, there are two different e-mail contacts available. Enter the e-mail address or addresses where you’d like to receive notifications. When you are finished making changes, click on Update, in the bottom right-hand corner of the page.'
  },
  {
    id: 'future-timestamp',
    question: 'The time stamp on one of my messages seems to have occurred in the future, or in the past. Is this a problem?',
    answer: 'There are possible conditions when the time stamp on a notification can be off by an hour. This sometimes occurs if a notification is posted around the top of any hour, and there’s a slight mismatch between the clock in the Infinity<sup>®</sup> System control and the web server clock. This does not affect any other function of the Infinity<sup>®</sup> System control, or the web applications, other than the notification time stamp being off by an hour.'
  },
  {
    id: 'system-status',
    question: 'How do I view the current status of my system?',
    answer: 'Log in to your account. Click on <a style="color: #3399CC;" class="parent-window-link" href="/account">My Locations</a>. In the area with the gray background, you will see the temperature settings for heating and cooling, along with the current temperature and humidity readings.'
  },
  {
    id: 'system-settings',
    question: 'How do I change settings from the website?',
    answer: 'Log in to your account. Click on <a style="color: #3399CC;" class="parent-window-link" href="/account">My Locations</a>. In the area with the gray background, above the Recent Notifications, there is an orange button labeled Change Settings. Click on this button and an application will open in a pop up window which will appear just like the control on your wall. Make any desired adjustments then click on Menu, scroll down, and click on Sync.'
  },
  {
    id: 'old-information',
    question: 'Why is “old” information being shown on the website?',
    answer: 'If the time shown on your status screen isn’t up to date, click on the refresh button to update the screen.'
  },
  {
    id: 'troubleshoot-wap',
    question: 'Who should I contact to troubleshoot the Wireless Access Point?',
    answer: 'Call the customer care center at 1-800-CARRIER'
  },
  {
    id: 'time-sync',
    question: 'How do I setup the time to synchronize with the web time?',
    answer: 'Both the time zone and enabling time synchronization must be done before time synchronization will begin. Once both steps are done, the time on the wall control will synchronize with the web time based on the time zone selected.'
  },
  {
    id: 'password-reset',
    question: 'Why can’t I log in to my account?',
    answer: 'If you’ve entered your password incorrectly too many times, you’ll be required to reset your password. This is done via the website, not via the smart phone app. Click on the Forgot Password link in the login area of the remote access website. A temporary password will be e-mailed to your address.'
  },
  {
    id: 'demo-mode',
    question: 'How do I use the app if I don’t have an account?',
    answer: 'On the login screen of the app, touch or click on Demo in the lower left-hand side of the screen. You won’t have access to your control, but you can see some of the menu screens and access features like photo export and scheduling, which can be uploaded to your control via USB.'
  },
  {
    id: 'screen-saver',
    question: 'How do I upload a screen saver?',
    answer: 'You’ll need to use the desktop app to format the picture so it can be saved to a USB flash drive and then uploaded to the wall control. If you don’t have a Wi-Fi control, or if you don’t have a user account, click on Demo in the lower left-hand side of the screen to use a demo of the app. When in Demo mode, you can create a schedule and format a screen saver picture, for use on your control.'
  },
  {
    id: 'heat-pump-below-10f',
    question: 'Why won’t my heat pump run below 10°F?',
    answer: 'If you have an 18VS heat pump, it is designed to work down to 10°F. Below that, the compressor won’t start. There’s nothing wrong with the unit, this is just a design limitation. If you don’t have an 18VS heat pump, there may be a temperature limit that has been set because the heat pump is not as effective as your back up heat source. This is set by the installer.'
  },
  {
    id: 'router-too-far',
    question: 'What if my router is too far from my control to get a good signal?',
    answer: '<p>We have tested a few range extenders that may be used with your Wi-Fi enabled wall control.</p>\n' +
      '        <p><a style="color: #3399CC;" href="http://www.docs.hvacpartners.com/idc/groups/public/documents/marketing/wifi_infinity_range_extenders.pdf" class="external-link sup-link">Enabling Infinity<sup>®</sup> Networks in Large Applications</a></p>'
  },
  {
    id: 'signal-strength',
    question: 'My control doesn’t show the Wi-Fi signal strength. Why not? <small>(SYSTXCCITW01 and SYSTXCCITW01‑A models only)</small>',
    answer: 'Only the most recent versions of the Infinity control have the capability to measure signal strength. These controls have a model number ending in ‑A. Previous controls do not have this capability.'
  },
  {
    id: 'clock-changed',
    question: 'My clock changed to a completely different time when I upgraded my software. What happened?',
    answer: '<p>New software upgrades include automatic time synchronization with the Internet; the time zone may not be set correctly.</p>\n' +
      '        <ol style="list-style-type: none;">\n' +
      '            <li>\n' +
      '                <p>On the wall control, select the “time / date” option in the setup menus.<br>\n' +
      '                    <img src="/static/images/help-clock-changed-01.jpg" alt="Main Menu"></p>\n' +
      '            </li>\n' +
      '            <li>\n' +
      '                <p>Select “set time zone”.<br>\n' +
      '                    <img src="/static/images/help-clock-changed-02.jpg" alt="Time and Date Menu"></p>\n' +
      '            </li>\n' +
      '            <li>\n' +
      '                <p>Choose the appropriate time zone, and select “save”.<br>\n' +
      '                    <img src="/static/images/help-clock-changed-03.jpg" alt="Set Time Zone Screen"></p>\n' +
      '            </li>\n' +
      '        </ol>\n' +
      '        <p>From the “time / date” menu, you can also disable time synchronization, manually adjust time and date when the synchronization is disabled, and setup daylight savings time preferences.</p>'
  },
  {
    id: 'firmware-update',
    question: 'My wall control doesn’t work properly after downloading new software. What do I do?',
    answer: '<p>Try these steps in this order:</p>\n' +
      '        <ol>\n' +
      '            <li>\n' +
      '                <p>Cycle power to the wall control by removing it from its base on the wall. This is done by pressing down on the two clips on the top of the wall control and rotating the wall control toward you on the bottom hinges. Re-install the wall control by placing it in the bottom hinges and rotating it back against the base, and re-engage the top clips. Allow the wall control to go through its startup routine. This may take several minutes since the wall control may attempt to re-install the software upgrade.</p>\n' +
      '            </li>\n' +
      '            <li>\n' +
      '                <p>If a power cycle does not clear the problem, you can load the wall control software manually. Go to the <a style="color: #3399CC;" class="parent-window-link" href="/Infinity/Downloads#firmware">Downloads</a> page of the <a style="color: #3399CC;" class="parent-window-link" href="/">www.MyInfinityTouch.com</a> website. Near the bottom of the page is the link for the software download process. Follow the instructions at that link.<br>\n' +
      '                    <img src="/static/images/help-firmware-update.jpg" alt="Downloads Page"></p>\n' +
      '            </li>\n' +
      '            <li>\n' +
      '                <p>If the wall control is still not functioning properly, contact the Carrier<sup>®</sup> customer care center at 1-800-227-7437, which is available between 8am and 5pm ET, Monday through Friday (holidays may be excluded).</p>\n' +
      '            </li>\n' +
      '            <li>\n' +
      '                <p>Contact your dealer for more assistance.</p>\n' +
      '            </li>\n' +
      '        </ol>'
  },
  {
    id: 'logon-delay',
    question: 'It takes a long time to log into the MyInfinityTouch<sup class="trademark">SM</sup> web page and/or to load the My Locations page on my browser. How can I fix this?',
    answer: '<p>The MyInfinityTouch<sup class="trademark">SM</sup> application downloads the latest information for all systems and locations associated with that account when you first log in to give you quick access to that information. This includes all Notifications that have not been deleted. If the log in process is taking a long time, it is likely being caused by a large number of old Notifications being loaded.</p>\n' +
      '        <p><img src="/static/images/help-logon-delay.gif" alt="Notifications List"></p>\n' +
      '        <p>To speed up log ins, delete older Notifications, or any Notifications that are no longer needed. Click on the check marks to the left of the Notifications to select those for deletion, and then click on Delete Selected. To delete all Notifications, click on Select All, and then click on Delete Selected.</p>'
  },
  {
    id: 'alexa-enable',
    question: 'How do I enable Amazon™ Alexa™ Skills and connect my Amazon Echo™ device with my Infinity® System Control?',
    answer: '<p>You can find the Amazon Alexa Skill for the Carrier® Infinity® System by going to <a style="color: #3399CC;" href="http://alexa.amazon.com/spa/index.html#skills" class="external-link">http://alexa.amazon.com/spa/index.html#skills</a> and searching for “Carrier Infinity Touch Control”.</p>\n' +
      '        <p class="pad-left">Open the Skill and follow the directions, including logging into your existing <a style="color: #3399CC;" href="/" class="parent-window-link">www.MyInfinityTouch.com</a> account from the Skill setup screens.</p>\n' +
      '        <p class="pad-left">NOTE: In order to use Amazon Alexa Skills, your Infinity System System Control must be a Wi-Fi® version, connected to the Internet through a home Wi-Fi network, and registered on <a style="color: #3399CC;" href="/" class="parent-window-link">www.MyInfinityTouch.com</a>. See <a style="color: #3399CC;" href="/" class="parent-window-link">www.MyInfinityTouch.com</a> for more information.</p>\n' +
      '        <p>You can search for other Alexa Skills here: <a style="color: #3399CC;" href="https://www.amazon.com/b?ie=UTF8&amp;node=13727921011" class="external-link">https://www.amazon.com/b?ie=UTF8&amp;node=13727921011</a>.</p>\n' +
      '        <p>You can find details on enabling Alexa Smart Home Skills here: <a style="color: #3399CC;" href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201848700" class="external-link">https://www.amazon.com/gp/help/customer/display.html?nodeId=201848700</a></p>\n' +
      '        <p><strong class="important"><em class="emphasis">Once you’ve enabled the skill, you must go to the Skills Menu and select Discover Devices, to use your wall control with Amazon Alexa.</em></strong></p>\n' +
      '        <p><img src="/static/images/help-alexa-smart-home-1.png" alt="Alexa Smart Home Devices"></p>'
  },
  {
    id: 'alexa-functions',
    question: 'What Infinity® System Control functions can I access with Amazon™ Alexa™ Skills and the Amazon Echo™ device?',
    answer: '<p>Alexa allows you to control your current temperature settings, fan settings, and Touch ’n Go™ activity through the Carrier Infinity System Skill. For all other settings, use the MyInfinity mobile app.</p>\n' +
      '        <p>The terms “command” and “utterances,” below, can be used interchangeably.</p>\n' +
      '        <p>NOTE: In order to use Amazon Alexa Skills, your Infinity System System Control must be a Wi-Fi® version, connected to the Internet through a home Wi-Fi network, and registered on <a style="color: #3399CC;" href="/" class="parent-window-link">www.MyInfinityTouch.com</a>. See <a style="color: #3399CC;" href="/" class="parent-window-link">www.MyInfinityTouch.com</a> for more information.</p>'
  },
  {
    id: 'alexa-system-name',
    question: 'How should I name my Infinity® System, Infinity System Control and zones for use with the Alexa™ Skill for Carrier Infinity® Systems?',
    answer: '<p>Ensure that all controls and zones within your account have unique names. There should not be any duplicate names present within your <a style="color: #3399CC;" href="/" class="parent-window-link">www.MyInfinityTouch.com</a> account. Avoid using numerical characters. Place spaces within system, system control or zone names for better recognition by Alexa. Do not enable both the “Carrier Infinity Touch Control” and “Bryant Evolution Connex Control” Amazon™ Alexa™ Skills. Enabling both will cause Amazon Alexa Smart Home to discover and list your devices twice.</p>\n' +
      '        <p>The [device name] used below is the name of an Infinity System Control, Infinity System, an individual zone in an Infinity Zoning System, or an Alexa group.</p>\n' +
      '        <p class="pad-left">For example, if your single-zone Infinity System is named “My Home,” then the [device name] becomes My Home.</p>\n' +
      '        <p class="pad-left">For Infinity Zoning Systems, the [device name] is the name of each individual zone, such as Master Bedroom, as named in the Infinity System Control.</p>\n' +
      '        <p class="pad-left">If the [device name] used is the name of the Infinity System or the Infinity System Control, zone 1 of a zoning system will be controlled.</p>\n' +
      '        <p>NOTE: All names of all systems and zones within each Infinity account MUST be unique; that is, no two systems or zones in a MyInfinity account may have the same name.</p>\n' +
      '        <p class="pad-left">For example, if there are two Infinity Systems in one account, not only must the system names be unique, but no two zones in the account may have the same name. That means that Main Home and Vacation Home cannot both have a zone named Master Bedroom or Living Room, for instance.</p>\n' +
      '        <p>If the names of Systems and Zones are not unique, confusion will ensue.</p>\n' +
      '        <p class="pad-left">You can edit Infinity System names within the <a style="color: #3399CC;" href="/" class="parent-window-link">www.MyInfinityTouch.com</a> consumer portal. Go to My Locations, and select Edit System Preferences, next to the name of each system.</p>\n' +
      '        <p class="pad-left">You can edit Infinity Zoning System zone names on the Infinity System Control, or in the MyInfinity mobile app. From the Main screen, select Menu, then select Zone Names.</p>\n' +
      '        <p class="pad-left">After you rename a System or Zone, you must go back to the Smart Home menu in the Alexa app and Discover Devices again, so that the changes will take affect in Amazon Alexa.</p>'
  },
  {
    id: 'alexa-current-temp',
    question: 'How do I control the current temperature settings with the Alexa™ Skill for Carrier® Infinity® Systems?',
    answer: '<p>You may use any of the following commands (“utterances”) to control the temperature using Alexa.</p>\n' +
      '        <ul>\n' +
      '            <li>Alexa, set the [device name] temperature to 68 degrees.</li>\n' +
      '            <li>Alexa, change the [device name] to 68 degrees.</li>\n' +
      '            <li>Alexa, turn my [device name] temperature to 68 degrees.</li>\n' +
      '            <li>Alexa, set [device name] to 68.</li>\n' +
      '            <li>Alexa, increase the temperature to 68 degrees in the [device name]</li>\n' +
      '            <li>Alexa, lower the [device name] temperature by 4 degrees.</li>\n' +
      '            <li>Alexa, cool down my [device name] by 4 degrees.</li>\n' +
      '            <li>Alexa, raise the [device name] by 4 degrees.</li>\n' +
      '            <li>Alexa, make my [device name] warmer by 4 degrees.</li>\n' +
      '            <li>Alexa, increase the [device name] temperature by 4 degrees.</li>\n' +
      '        </ul>\n' +
      '        <p>Note that the word order used when giving Alexa a command is very important. If you ask Alexa to make a change that she doesn’t understand, you may need to change the way you say it for it to understand what you want to happen. See the “utterances” above.</p>\n' +
      '        <p>When controlling the temperature settings via Alexa if the [device name] used is the name of the Infinity System, zone 1 of a zoning system will be controlled.</p>\n' +
      '        <p>Controlling the temperature via Alexa will activate a Manual Touch ’n Go™ activity hold with heat and cool setpoints to attain the requested temperature for the current mode (Heat, Cool or Auto). The manual activity hold is scheduled until the start of the next program period. For Infinity System Controls running firmware version less than 14.00, the hold is scheduled until the end of the current day (12:00 am).</p>\n' +
      '        <p>These commands never change your current system mode (Off, Heat, Cool, or Auto). The system mode can be changed on the Infinity System Control, or the MyInfinity mobile app.</p>\n' +
      '        <p><strong class="important">It may be helpful to review the device list in your Alexa Smart Home screen in order to find the names of available devices in your system. See the screenshot below for an example of available zones.</strong></p>\n' +
      '        <p><img src="/static/images/help-alexa-smart-home-2.png" alt="Alexa Smart Home Devices"></p>'
  },
  {
    id: 'alexa-current-fan',
    question: 'How do I control the current fan settings with the Alexa™ Skill for Carrier® Infinity® Systems?',
    answer: '<p>You may use any of the following commands to control the fan using Alexa:</p>\n' +
      '        <ul>\n' +
      '            <li>Alexa, Turn off [device name] fan</li>\n' +
      '            <li>Alexa, Turn on [device name] fan</li>\n' +
      '            <li>Alexa, increase [device name] fan by “50” percent.</li>\n' +
      '            <li>Alexa, decrease [device name] fan by {number} percent.</li>\n' +
      '        </ul>\n' +
      '        <p>The [device name] is the name of an Infinity System Control fan, zone fan, or an Alexa group.</p>\n' +
      '        <p>When controlling the fan settings via Alexa if the [device name] used is the name of the Infinity System, zone 1 will be controlled.</p>\n' +
      '        <p>Controlling the fan settings via Alexa will update the fan settings of the current Touch ’n Go™ activity (Home, Away, Sleep, Wake, or Manual).</p>\n' +
      '        <p>When a “Turn On” command is used Alexa will attempt to set the fan setting to the last setting prior to the fan being turned off.</p>\n' +
      '        <p>When setting the fan setting to a specific percentage, Alexa interprets the percentage as follows:</p>\n' +
      '        <ul>\n' +
      '            <li>Auto = 0%</li>\n' +
      '            <li>Low = 1% – 33%</li>\n' +
      '            <li>Med = 34% – 66%</li>\n' +
      '            <li>High = 67% – 100%</li>\n' +
      '        </ul>\n' +
      '        <p>When using the “Increase” command Alexa will always increment the current fan setting.</p>\n' +
      '        <p><strong class="important">Example:</strong> If the current fan setting is “Low” and you use a “Increase fan by 1%” command Alexa will update the fan setting to “Med”.  If you repeat the same command then Alexa will update the fan setting to “High”.</p>\n' +
      '        <p>When using the “Decrease” command Alexa will always decrement the current fan setting.</p>\n' +
      '        <p><strong class="important">Example:</strong>  If the current fan setting is “High” and you use a “Decrease fan by 1%” command Alexa will update the fan setting to “Med”.  If you repeat the same command then Alexa will update the fan setting to “Low”.</p>\n' +
      '        <p>If the current fan setting is “Low” and you use a “Decrease” command Alexa will update the fan setting to “Auto”.</p>\n' +
      '        <p>If the current fan setting is “Auto” and you use an “Increase” command Alexa will update the fan setting to “Low”.</p>\n' +
      '        <p>Note that, as of 3-20-2017, the only “utterances” you can use for setting the fan speed are Low and High. To set the fan to Medium, you must use a percentage, or increment/decrement as shown above. The command for Medium fan <em class="emphasis">may</em> be added in the future.</p>'
  },
  {
    id: 'alexa-touchngo',
    question: 'How do I control the current Touch ’n Go™ Activity with Alexa?',
    answer: '<p>You may use any of the following commands to control the Touch ’n Go™ activity using Alexa:</p>\n' +
      '        <ul>\n' +
      '            <li>Alexa, Turn on [device name] <em class="emphasis">activity</em></li>\n' +
      '        </ul>\n' +
      '        <p>…where <em class="emphasis">activity</em> is one of the Touch ‘n Go activities of Manual, Home, Awake, Sleep or Away.</p>\n' +
      '        <p>The [device name] is the name of an Infinity System Control, zone, or an Alexa group.</p>\n' +
      '        <p>When controlling the Touch ’n Go™ Activity settings via Alexa if the [device name] used is the name of the Infinity System, zone 1 will be controlled.</p>\n' +
      '        <p>When a “Turn On” command is used Alexa will update the currently running activity to the specified Touch ’n Go™ activity. This activity will continue until the start of the next program period. For Infinity System Controls running firmware version less than 14.00, the activity will continue until the end of the current day (12:00 am).</p>'
  },
  {
    id: 'alexa-system-mode',
    question: 'Am I able to change the Infinity® System mode between Heat, Cool, Off and Auto with the Alexa™ Skill for Carrier® Infinity® Systems?',
    answer: 'At this time Alexa does not support this functionality. You can change the system mode on the Infinity System Control, or use the MyInfinity Touch apps to accomplish this functionality.'
  },
  {
    id: 'more-help',
    question: 'What if none of my questions are answered here?',
    answer: 'Call the customer care center at 1-800-CARRIER and they will either answer your question, or they will research the question and respond when they have the answer.'
  }
];
