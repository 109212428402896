import { Idu, Odu, SystemConfig, TempUnit, Zone } from '../../../types/system';
import { toArray } from '../utils';

export const toSystemZoneStatus = (zoneObj: any): Zone => {
  if (!zoneObj) {
    return null;
  }
  return {
    id: zoneObj.$?.id,
    name: zoneObj.name,
    temp: zoneObj.rt,
    activity: zoneObj.currentActivity,
    status: zoneObj.zoneconditioning,
    heatSetpoint: zoneObj.htsp,
    coolSetpoint: zoneObj.clsp,
    fanSetting: zoneObj.fan,
    reportedHumidity: zoneObj.rh
  };
};

export interface SystemStatus {
  name: string;
  outsideTemp: number;
  tempUnit: TempUnit;
  mode: string;
  fanSetting: string;
  lastReported: string;
  odu: Odu[],
  idu: Idu[],
  zones: Zone[];
}

export const toSystemStatus = (systemStatusObj: any): SystemStatus => {
  let status = systemStatusObj?.status;
  if (!status) {
    return {
      name: null,
      outsideTemp: null,
      tempUnit: null,
      mode: null,
      fanSetting: null,
      lastReported: null,
      odu: null,
      idu: null,
      zones: []
    };
  }

  let zones: Zone[] = toArray(status.zones?.zone).map(zoneResponse => toSystemZoneStatus(zoneResponse));

  return {
    name: status.name,
    outsideTemp: status.oat,
    tempUnit: status.cfgem,
    mode: status.mode,
    fanSetting: status.vent,
    lastReported: new Date(status.timestamp).toISOString(), // this is how to convert utc time to local time
    odu: status?.odu,
    idu: status?.idu,
    zones: zones
  };
};

export const toSystemConfig = (systemConfigObj: any): SystemConfig => {
  let config = systemConfigObj?.config;
  if (!config) {
    return null;
  }

  return {
    mode: config.mode,
    humidityHome: {
      humid: config.humidityHome.humid,
      rhtg: config.humidityHome.rhtg
    },
    humidityAway: {
      humid: config.humidityAway.humid,
      rhtg: config.humidityAway.rhtg
    },
    humidityVacation: {
      humid: config.humidityVacation.humid,
      rhtg: config.humidityVacation.rhtg
    },
    zones: config.zones?.zone?.map(zone => {
      return {
        id: zone['$'].id,
        name: zone.name
      };
    })
  };
};
