import { InfinityNotificationPreference, toInfinityNotificationPreference } from '../system-notification-pref';
import { SystemPreferenceDTO } from '../../../types/system-preference';

export interface SystemPreferenceRequest {
  name: string;
  promoEmail: boolean;
  dealer: {
    pid?: number | string;
    name: string;
    street1: string;
    street2: string;
    city: string;
    state: string;
    country: string;
    postal: string;
    phone: string;
  };
  system_notification_prefs: InfinityNotificationPreference;
}

export interface SystemPreferenceRequestXML {
  system_preference: SystemPreferenceRequest;
}

export const toSystemPreferenceRequest = (systemPreferenceDto: SystemPreferenceDTO): SystemPreferenceRequest => {
  let request: SystemPreferenceRequest = {
    name: systemPreferenceDto.systemName,
    promoEmail: systemPreferenceDto.userPromo,
    dealer: {
      pid: systemPreferenceDto?.dealer?.pid,
      name: systemPreferenceDto?.dealer?.name,
      street1: systemPreferenceDto?.dealer?.street,
      street2: systemPreferenceDto?.dealer?.unit,
      city: systemPreferenceDto?.dealer?.city,
      state: systemPreferenceDto?.dealer?.state,
      country: systemPreferenceDto?.dealer?.country,
      postal: systemPreferenceDto?.dealer?.zip,
      phone: systemPreferenceDto?.dealer?.phone
    },
    system_notification_prefs: toInfinityNotificationPreference(
      systemPreferenceDto.dealer,
      systemPreferenceDto.notificationPreference
    )
  };

  return request;
};

export const toSystemPreferenceRequestXML = (updatedSystem): SystemPreferenceRequestXML => {
  return {
    system_preference: toSystemPreferenceRequest(updatedSystem)
  };
};
