import { ThirdPartyApp, Permissions } from '../../../types/third-party-app';
import { toArray, convertOnOffStringToBoolean } from '../utils';

export const toUserApps = (userAppResponse): Array<ThirdPartyApp> => {
  let responseApps = toArray(userAppResponse.apps.app);
  let apps: Array<ThirdPartyApp> = [];

  if (!userAppResponse.apps) {
    return [];
  }

  for (let app of responseApps) {
    let appDetails: ThirdPartyApp;
    let permissons: Permissions = {
      viewSystem: false,
      viewUser: false,
      viewUtilityEvents: false,
      modifySystem: false,
      modifyUtilityEvents: false
    };
    for (let scope of toArray(app.scopes.scope)) {
      if (scope.id === 'Read-System') {
        permissons.viewSystem = convertOnOffStringToBoolean(scope.isGranted);
      } else if (scope.id === 'Read-User') {
        permissons.viewUser = convertOnOffStringToBoolean(scope.isGranted);
      } else if (scope.id === 'Read-UtilityEvents') {
        permissons.viewUtilityEvents = convertOnOffStringToBoolean(scope.isGranted);
      } else if (scope.id === 'Write-System') {
        permissons.modifySystem = convertOnOffStringToBoolean(scope.isGranted);
      } else if (scope.id === 'Write-UtilityEvents') {
        permissons.modifyUtilityEvents = convertOnOffStringToBoolean(scope.isGranted);
      }
    }
    appDetails = {
      id: app.id,
      name: app.displayName,
      authDate: app.authorizationDate,
      permissions: permissons
    };

    apps.push(appDetails);
  }

  return apps;
};
